import React, {useEffect, useState} from "react";
//@ts-ignore
import {useTranslation} from "react-i18next";
//@ts-ignore
import {useDebounce} from "use-debounce";
import Loading from "../../Loading";
import useIntersectionObserver from "../../../hooks/useIntersectionObserver";
import OrderRow from "./OrderRow";
//@ts-ignore
import {ProfitCenter} from "../../../types";
import {OrderSearchQuery, TransactionHeaderSimple} from "../types";
//@ts-ignore
import Select from "react-select";
import Picker, {Option} from "../settings/Picker";
import {cyrb53} from "../../../cyrb53";

const Orders = () => {
  const debug = false;
  const {t} = useTranslation();
  const statuses = [
    {value: 'Added', label: t('pick-n-pay.orders.status.added')},
    {value: 'Started', label: t('pick-n-pay.orders.status.started')},
    {value: 'Delivered', label: t('pick-n-pay.orders.status.delivered')},
    {value: 'Finished', label: t('pick-n-pay.orders.status.finished')},
    {value: 'ArivedFromClient', label: t('pick-n-pay.orders.status.arrivedfromClient')},
    {value: 'CalculatedPrice', label: t('pick-n-pay.orders.status.calculatedPrice')},
    {value: 'Cancelled', label: t('pick-n-pay.orders.status.cancelled')},
    {value: 'Rollbacked', label: t('pick-n-pay.orders.status.rollbacked')},
    {value: 'RegistrationFailed', label: t('pick-n-pay.orders.status.registrationfailed')},
    {value: 'AuthorizeFailed', label: t('pick-n-pay.orders.status.authorizefailed')},
    {value: 'PlaceOrderFailed', label: t('pick-n-pay.orders.status.placeorderfailed')},
    {value: 'CaptureFailed', label: t('pick-n-pay.orders.status.capturefailed')},
    {value: 'QueryTransactionFailed', label: t('pick-n-pay.orders.status.querytransactionfailed')},
    {value: 'CommitPaymentFailed', label: t('pick-n-pay.orders.status.commitpaymentfailed')},
    {value: 'DeleteOrderOK', label: t('pick-n-pay.orders.status.deleteorderok')},
    {value: 'DeleteOrderFailed', label: t('pick-n-pay.orders.status.deleteorderfailed')},
    {value: 'Unknown', label: t('pick-n-pay.orders.status.unknown')}
  ];
  const types = [
    {value: 'OrderApp', label: t('pick-n-pay.orders.type.orderapp')},
    {value: 'OrderAppTakeAway', label: t('pick-n-pay.orders.type.orderapptakeaway')},
  ];
  

  const getDefaultStatusFilter = () => {
    return [
      statuses.find((status) => status.value === 'Added')!,
      statuses.find((status) => status.value === 'Started')!,
      statuses.find((status) => status.value === 'Delivered')!,
      statuses.find((status) => status.value === 'Finished')!,
    ];
  }
  const getDefaultFilter = () => {
    return {
      status: getDefaultStatusFilter(),
      type: [
        types.find((type) => type.value === 'OrderApp')!,
        types.find((type) => type.value === 'OrderAppTakeAway')!
      ],
      customerCode: null,
      createdFrom: null,
      createdFromTime: null,
      createdTo: null,
      createdToTime: null,
      profitCenterCode: null,
      deliverySpotId: null
    };
  }

  const {isIntersecting, ref} = useIntersectionObserver({ threshold: 0.5 });
  const [results, setResults] = useState<TransactionHeaderSimple[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showFilterPanel, setShowFilterPanel] = useState(false);
  const [lastPage, setLastPage] = useState(false);
  const [shops, setShops] = useState<ProfitCenter[]>([]);
  const [query, setQuery] = useState<OrderSearchQuery>({
    text: "",
    filter: getDefaultFilter(),
    settings: {
      pageIndex: 0,
      pageSize: 20,
      clearResults: true
    }
  });

  useEffect(() => {
    fetch("/api/pick-n-pay/shops")
      .then(response => response.json())
      .then(data => {
        const shops = data.map((shop: any) => {
          return {
            value: shop.id,
            label: shop.description
          };
        });
        setShops(shops);
      });
  }, []);

  const minDate = new Date(2022, 1, 1).toISOString().split('T')[0];
  const maxDate = new Date().toISOString().split('T')[0];

  const [debouncedQuery] = useDebounce(query, 500);
  useEffect(() => {
    console.log('useEffect[debouncedQuery] fetchData');
    fetchData();
  }, [debouncedQuery]);

  const fetchData = () => {
    if (lastPage || loading) 
      return;

    setLoading(true);

    console.log('fetching data pageIndex:', query.settings.pageIndex, 'clearResults:', query.settings.clearResults);
    const queryString = new URLSearchParams();
    //query
    queryString.set('query', debouncedQuery.text);

    //filter
    if (query.filter.profitCenterCode) {
      queryString.set('fProfitCenter', query.filter.profitCenterCode);
    }
    if (query.filter.customerCode) {
      queryString.set('fCustomerCode', query.filter.customerCode);
    }

    if (query.filter.status.length > 0) {
      query.filter.status.map((status) => {
        queryString.append('fStatus', status.value);
      });
    }

    if (query.filter.type.length > 0) {
      query.filter.type.map((type) => {
        queryString.append('fType', type.value);
      });
    }

    if (query.filter.createdFrom) {
      queryString.set('fCreatedFrom', query.filter.createdFrom);
      if (query.filter.createdFromTime) {
        queryString.set('fCreatedFromTime', query.filter.createdFromTime);
      }
    }
    if (query.filter.createdTo) {
      queryString.set('fCreatedTo', query.filter.createdTo);
      if (query.filter.createdToTime) {
        queryString.set('fCreatedToTime', query.filter.createdToTime);
      }
    }
    if(query.filter.deliverySpotId !== null){
      queryString.set('fSpotid', query.filter.deliverySpotId.toString());
    }

    //settings
    queryString.set('pageIndex',query.settings.pageIndex.toString());
    queryString.set('pageSize', query.settings.pageSize.toString());

    fetch(`api/pick-n-pay/orders/search?${queryString.toString()}`)
      .then(response => response.json())
      .then(data => {
        if (data.items.length < query.settings.pageSize) {
          setLastPage(true);
        }
        //console.log("lastPage:", data.items.length < query.settings.pageSize);
        if (query.settings.clearResults) {
          console.log(`clearing items`)
          setResults(data.items as any[]);
        } else {
          console.log(`appending ${data.items.length} items`)
          setResults([...results, ...data.items as any[]]);
        }
        setTotalCount(data.totalCount);
        setLoading(false);
      });
  }

  
  useEffect(() => {
    console.log('useEffect[isIntersecting] setQuery','isIntersecting:',isIntersecting);
    if(isIntersecting){
      setQuery({
        ...query,
        settings: {
          ...query.settings,
          pageIndex: query.settings.pageIndex + 1,
          clearResults: false
        }
      });
    }
  }, [isIntersecting]);

  const populateCustomers = (query: string): Promise<Option[]> => {
    return fetch(`api/pick-n-pay/customers/search?query=${encodeURIComponent(query)}`)
      .then(response => response.json())
      .then(data => {
        return data.items.map((c: any) => {
          return {
            value: c.customerCode ?? '',
            label: `${c.name} ${c.lastName}`
          };
        });
      });
  }

  const toggleFilterPanel = (e: any) => {
    e.preventDefault();
    setShowFilterPanel(!showFilterPanel);
  }
  const clearFilter = (e: any) => {
    e.preventDefault();
    console.log('clearing filter');
    setLastPage(false);
    setQuery({
      ...query,
      filter: getDefaultFilter(),
      settings: {
        ...query.settings,
        pageIndex: 0,
        clearResults: true
      }
    });
  }

  const empty = results.length === 0 && !loading;

  return (
    <div className={"pick-n-pay orders-page"}>
      <h1>{t('pick-n-pay.orders.list.header')}</h1>
      <div className={"filter-container"}>
        <form onSubmit={(e)=>{
          e.preventDefault();
        }} className={"filters"}>
          <div className={"input-group query-group"}>
            <label htmlFor={"query"} className={"form-label input-group-text"}>{t('pick-n-pay.orders.list.query.label')}</label>
            <input id={"query"} name={"query"} className={"form-control"} type={"search"}
                   placeholder={t('pick-n-pay.orders.list.query.placeholder')}
                   onChange={(e) => {
                     setLastPage(false);
                     setQuery({...query, text: e.target.value, settings: {...query.settings, pageIndex: 0, clearResults: true }});
                    }
                   }/>
            <button type={"button"} className={"btn btn-toggle-filters"} onClick={toggleFilterPanel}>{t(`pick-n-pay.filter-panel.filterBtnText`)}</button>
            <p className={"form-info"}>{t(`pick-n-pay.orders.list.query.description`)}</p>
          </div>

          {showFilterPanel && (
          <div className={`filter-panel ${showFilterPanel ? 'visible' : 'hidden'}`}>
            <div className="filter-panel-header">
              <h2>{t(`pick-n-pay.filter-panel.header`)}</h2>
              <button type={"button"} onClick={toggleFilterPanel} className={"btn btn-close"}>{t(`pick-n-pay.filter-panel.close`)}</button>
            </div>
            <div className={"input-group"}>
              <label>{t(`pick-n-pay.orders.dateFrom.label`)}</label>
              <input aria-label="Date from" max={query.filter.createdTo ?? ""} min={minDate} type="date" value={query.filter.createdFrom ??""} onChange={(e) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, createdFrom: e.target.value}, settings: {...query.settings, pageIndex: 0, clearResults: true}});
              }}/>
              <input disabled={!query.filter.createdFrom} type={"time"} value={query.filter.createdFromTime ?? ""} onChange={(e) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, createdFromTime: e.target.value}, settings: {...query.settings, pageIndex: 0, clearResults: true}});
              }}/>
              <p className={"form-info"}>{t(`pick-n-pay.orders.dateFrom.description`)}</p>
            </div>
            <div className={"input-group"}>
              <label>{t(`pick-n-pay.orders.dateTo.label`)}</label>
              <input aria-label="Date to" max={maxDate} min={query.filter.createdFrom ?? ""} type="date" value={query.filter.createdTo ??""} onChange={(e) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, createdTo: e.target.value}, settings: {...query.settings, pageIndex: 0, clearResults: true}});
              }}/>
              <input disabled={!query.filter.createdTo} type={"time"} value={query.filter.createdToTime ?? ""}  onChange={(e) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, createdToTime: e.target.value}, settings: {...query.settings, pageIndex: 0, clearResults: true}});
              }}/>
              <p className={"form-info"}>{t(`pick-n-pay.orders.dateTo.description`)}</p>
            </div>


            <div className={"input-group"}>
              <div className={"input-group"}>
                <label>{t(`pick-n-pay.orders.status.label`)}</label>
                <Select name={"fStatus"} onChange={(e: any[]) => {
                  setLastPage(false);
                  setQuery({...query, filter: {...query.filter, status: e}, settings: {...query.settings, pageIndex: 0, clearResults: true}});
                }} value={query.filter.status} defaultValue={query.filter.status} options={statuses} isMulti={true}/>
                <p className={"form-info"}>{t(`pick-n-pay.orders.status.description`)}</p>
              </div>
            </div>
            <div className={"input-group"}>
              <label>{t(`pick-n-pay.orders.type.label`)}</label>
              <Select name={"fType"} onChange={(e: any[]) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, type: e}, settings: {...query.settings, pageIndex: 0, clearResults: true}});
              }} value={query.filter.type} defaultValue={query.filter.type} options={types} isMulti={true}/>

              <p className={"form-info"}>{t(`pick-n-pay.orders.type.description`)}</p>
            </div>

            <Picker
              label={t('pick-n-pay.orders.customer.label')}
              showClear={true}
              queryOptions={populateCustomers}
              description={t('pick-n-pay.orders.customer.description')}
              value={query.filter.customerCode}
              onChange={(e: any) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, customerCode: e}, settings: {...query.settings, pageIndex: 0, clearResults: true}})
              }}
            />

            <Picker
              label={t('pick-n-pay.orders.profitCenter.label')}
              showClear={true}
              options={shops}
              description={t('pick-n-pay.orders.profitCenter.description')}
              value={query.filter.profitCenterCode}
              onChange={(e: any) => {
                setLastPage(false);
                setQuery({...query, filter: {...query.filter, profitCenterCode: e}, settings: {...query.settings, pageIndex: 0, clearResults: true}})
              }}
            />
            <div className={"btn-group filter-panel-footer"}>
              <button onClick={clearFilter} className={"btn btn-clear"}>{t(`pick-n-pay.filter-panel.btnClear`)}</button>
              <button onClick={toggleFilterPanel} className={"btn btn-primary"}>{t(`pick-n-pay.filter-panel.btnDone`)}</button> 
            </div>
          </div>
          )}
        </form>
      </div>


      <div className={"container"}>
        <span className={"total-count"}>
            {t('pick-n-pay.orders.list.totalCount', { count: results.length, total: totalCount })}
        </span>
        {debug && (
          <div className={"debug-page-index"}>
            loading: {loading.toString()}<br/>
            pageIndex: {query.settings.pageIndex}<br/>
          </div>
        )}
        <div className={"order-search-result search-result-container"}>
          <div className={"search-result-header"}>
            <span>{t('pick-n-pay.orders.list-headers.created')}</span>
            <span>{t('pick-n-pay.orders.list-headers.orderNr')}</span>
            <span>{t('pick-n-pay.orders.list-headers.status')}</span>
            <span>{t('pick-n-pay.orders.list-headers.type')}</span>
            <span>{t('pick-n-pay.orders.list-headers.shop')}</span>
            <span>{t('pick-n-pay.orders.list-headers.customer')}</span>
            <span>{t('pick-n-pay.orders.list-headers.total')}</span>
            <span></span>
          </div>
          
          <ol className={"search-result"}>
            {results.map((order) => (
              <OrderRow order={order} key={order.guid}/>
            ))}
          </ol>
          
          {empty && (
            <div className={"empty-result"}>
              <p>{t('pick-n-pay.orders.list.empty')}</p>
            </div>
          )}
          
          {loading && <Loading visible={true}/>}

          {!loading && (
            <div className={"scroll-observer"} ref={ref}></div>
          )}
        </div>
      </div>


    </div>
  )
}
export default Orders;