import i18n from 'i18next';
import { initReactI18next} from "react-i18next";
import english from './i18n/en.json'
import swedish from './i18n/sv.json'
import finnish from './i18n/fi.json'

const resources = {
  en: {
    translation: english
  },
  sv: {
    translation: swedish
  },
  fi: {
    translation: finnish
  }
}

let lang = localStorage.getItem("locale");
if(lang === null){
  localStorage.setItem("locale", "en");
  lang = "en";
}

i18n.use(initReactI18next)
  .init({
    resources,
    lng: lang,
    debug:true
    // keySeparator: false,
    // interpolation: {
    //   escapeValue: false
    // }
  });

i18n.on('languageChanged', (lng:string) => {
    console.log('Changed language to',lng);
});

export default i18n;