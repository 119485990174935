import React, {useEffect} from "react";
import {Check2Square, Square} from "react-bootstrap-icons";
import {PnpSettings} from "../settings/Settings";
import Loading from "../../Loading";
import {Terms} from "../types";
import {useTranslation} from "react-i18next";

interface TaskProps {
    href: string;
    text: string;
    done: boolean;

}
const Task = (props:TaskProps)=>{
    return (
        <>
            <a href={props.href}>{props.text}</a>
        </>
    )

}

const PostInstallTasks = ()=>{
    const [settings, setSettings] = React.useState<PnpSettings>();
    const [loading, setLoading] = React.useState(true);
    const [hasProviders, setHasProviders] = React.useState(false);
    const [hasShops, setHasShops] = React.useState(false);
    const [hasMenus, setHasMenus] = React.useState(false);
    const [hasTerms, setHasTerms] = React.useState(false);
    
    const {t} = useTranslation();
    
    useEffect(() => {
        setLoading(true);
        const promises = [
            fetch('api/pick-n-pay/shops')
                .then(r => r.json())
                .then(data => {
                    setHasShops(data.length > 0);
                }),
            fetch('api/pick-n-pay/payment-providers')
                .then(response => response.json())
                .then(data => {
                    setHasProviders(data.items.length > 0);
                }),
            fetch('api/pick-n-pay/settings')
                .then(r => r.json())
                .then(data => {
                    const settings = data as PnpSettings;
                    setSettings(settings);
                }),
            fetch("/api/pick-n-pay/terms?returnDefault=false")
                .then((response) => {
                    setHasTerms(response.status === 200);
                }),
            fetch("/api/pick-n-pay/menus")
              .then(r => r.json())
              .then((data) => {
                  setHasMenus(data.length > 0);
              })
        ];
        Promise.all(promises).then(() => {
            setLoading(false);
        });
        
    }, []);
    
    const settingsDone = ()=>{
        if(!settings)
            return false;
        
        let done = settings.winpos.defaultCurrency
            && settings.winpos.cashierNumber
            && settings.winpos.paymentNumber
            && settings.enabledLanguages.length > 0 
            && settings.orderTypes.length > 0 
            && settings.enabledPaymentProviders.length > 0
            && settings.siteUrl;
            
        return !!done;
    }
    
    const allDone = ()=>{
        return settingsDone() && hasProviders && hasShops && hasTerms && hasMenus;
    }
    
    return (
        <>
        <div className={`widget post-install-tasks ${allDone()?"all-tasks-done":"tasks-left"}`}>
            <h2>{t('pick-n-pay.post-install-tasks-widget.header')}</h2>
            <Loading visible={loading}/>
            {!loading && (
            <>
            <ol>
                <li className={`task ${settingsDone()?'task-done':''}`}><Task href={"/pick-n-pay/settings"} text={t('pick-n-pay.post-install-tasks-widget.settings')} done={settingsDone()} /></li>
                <li className={`task ${hasProviders?'task-done':''}`}><Task href={"/pick-n-pay/settings/payment-providers"} text={t('pick-n-pay.post-install-tasks-widget.payment-providers')} done={hasProviders} /></li>
                <li className={`task ${hasShops?'task-done':''}`}><Task href={"/pick-n-pay/shops"} text={t('pick-n-pay.post-install-tasks-widget.shops')} done={hasShops} /></li>
                <li className={`task ${hasMenus?'task-done':''}`}><Task href={"/pick-n-pay/menus"} text={t('pick-n-pay.post-install-tasks-widget.menus')} done={hasMenus} /></li>
                <li className={`task ${hasTerms?'task-done':''}`}><Task href={"/pick-n-pay/terms"} text={t('pick-n-pay.post-install-tasks-widget.terms')} done={hasTerms} /></li>
            </ol>

            {allDone() && (
                <span className={"ready-for-live"}>
                    {t('pick-n-pay.post-install-tasks-widget.ready')}
                </span>
            )}
            </>
            )}
        </div>
        </>
    )
}

export default PostInstallTasks;