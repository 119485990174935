const getFormatters = (settings:any|null) => {
    const locale = settings?.locale ?? "en";
    const currency = settings?.currency ?? "EUR";
    return {
        currency: new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
            currencyDisplay: 'narrowSymbol'
        }),
        number: {
            format: (value:number) => {
                return new Intl.NumberFormat(locale).format(value) + " st"
            }
        },
        percentage: {
            format: (value:number|string) => {
                let num = Number(value);
                if(num > 1)
                    num = num / 100;
                return num.toLocaleString(locale, {
                    style: 'percent', 
                    minimumFractionDigits: 1, 
                    maximumFractionDigits: 2
                })
            }
        }
    };
}

export const useFormatters = (settings:any) => {
    return getFormatters(settings);   
}