import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useUser} from "../UserContext";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useQuery} from "../hooks/useQuery";
import Loading from "./Loading";

interface LoginState {
  email: string;
  password: string;
}
const Login = () => {
  const query = useQuery();
  const initialError = query.has('error') ? query.get('error') : null;
  if(initialError){
    query.delete('error');
    window.history.replaceState({}, document.title, window.location.pathname + '?' + query.toString());
  }
  
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState<LoginState>({email: "", password: ""});
  const [errorCode, setErrorCode] = useState<string | null>();
  const [winposLoginError, setWinposLoginError] = useState<string|null>(initialError);
  const [showFail, setShowFail] = useState(initialError !== null);
  
  const {user, onLogin} = useUser();
  const navigate = useNavigate();
  
  const {t} = useTranslation();
  
  useEffect(()=>{
    if(user){
      navigate('/');
    }
  },[user]);

  const submit = (event:FormEvent) => {
    setShowFail(false);
    event.preventDefault();
    setLoading(true);
    onLogin(state.email, state.password)
        .then((success:boolean) => {
          setLoading(false);
          if (success)
            navigate('/');
          else{
            setErrorCode('generic');
            setShowFail(true);
          }
        })
  }

  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value
    const name = target.name;

    setState({
          ...state,
          [name]: value
        }
    );
  }

  return (
      <>
        <div className={"login"}> 
          <form onSubmit={submit} className={"login-dialog"}>
            <h3>{t('login.header')}</h3>
            
            <div className={"e-mail-input"}>
              <label htmlFor={"email"}>{t('login.email.label')}</label>
              <input type={"email"} id={"email"} name={"email"} value={state.email} onChange={handleChange} placeholder={t('login.email.placeholder')}/>
            </div>
            
            <div className={"password-input"}>
              <label htmlFor={"password"}>{t('login.password.label')}</label>
              <input type={"password"} id={"password"} name={"password"} value={state.password} onChange={handleChange} placeholder={t('login.password.placeholder')}/>
            </div>

            {errorCode &&(
                <div className={"alert alert-danger"} role="alert">
                  <p>{t(`login.errors.${errorCode}`)}</p>
                </div>
            )}
            <div className={'login-footer'}>
              <Link className={"reset-password"} to={"/reset-password"}>{t('login.reset-password.text')}</Link>
              <button disabled={loading} className={"btn btn-primary"} type={"submit"}>{t('login.login.text')}</button>
            </div>
          </form>
          <Loading visible={loading} />
        
          <form className={"external-login winpos"} method={"POST"} action={"/account/login-winpos"}>
            <span>{t('login.login-winpos.text')}</span>
            <button className={"ms-login-btn"} area-label={t('login.login-winpos.text')} type={"submit"}></button>
            {winposLoginError &&(
              <div className={"alert alert-danger"} role="alert">
                <p>{t(`login.errors.${winposLoginError}`)}</p>
              </div>
            )}
          </form>
        </div>
      </>
  );
}
export default Login;