
import {ButtonHTMLAttributes, MouseEvent, useState, KeyboardEvent, PropsWithChildren} from "react";
import i18n from "../../../i18n";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement>{   
  message?:string;
  onConfirm?:()=>void;
  onCancel?:()=>void;
  cancelText?:string;
  confirmText?:string;
}

const ButtonConfirm = (props:PropsWithChildren<Props>)=>{
  const {message, onConfirm, onCancel, cancelText, confirmText, ...rest} = props;
  
  const [showConfirm, setShowConfirm] = useState(false);
  const buttonClick = (e:MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowConfirm(true)
    props.onClick && props.onClick(e);  
  };
  
  const keyDown = (e:KeyboardEvent<HTMLDivElement>)=>{
    if(e.key === "Enter"){
      props.onConfirm && props.onConfirm()
      setShowConfirm(true)
    }
    if(e.key === "Escape"){
      props.onCancel && props.onCancel();
      setShowConfirm(false)
    }
  }
  
  return (<div className={`btn-confirm-container ${showConfirm?"confirm-open":""}`} onKeyDown={keyDown}>
        <button {...rest} onClick={buttonClick}>{props.children}</button>
        {showConfirm && (
        <div className={"btn-confirm-dialog"}>
          <p>{props.message}</p>
          <div className={"dialog-footer"}>
            <button className={"btn btn-cancel"} onClick={(e)=>{
              e.preventDefault();
              props.onCancel && props.onCancel(); 
              setShowConfirm(false);
            }}>{props.cancelText}</button>
            <button className={"btn btn-primary"} onClick={(e)=>{
                e.preventDefault();
                props.onConfirm && props.onConfirm(); 
                setShowConfirm(false);
              }}>{props.confirmText}</button>
          </div>
          
        </div>)}
      </div>
    )
}
ButtonConfirm.defaultProps = {
  message:i18n.t('components.buttonConfirm.message'),
  cancelText:i18n.t("components.buttonConfirm.cancelText"),
  confirmText:i18n.t("components.buttonConfirm.confirmText")
}

export default ButtonConfirm;