import React from "react";
import {ColorResult, RGBColor, SketchPicker} from "react-color";
import rgbHex from "rgb-hex";

interface State {
  color: RGBColor;
  visible: boolean;
}

interface Props {
  onChange: (color: RGBColor) => void;
  color: RGBColor;
  label?: string;
}

const ColorPicker = (props: Props) => {
  const [state, setState] = React.useState<State>({color: props.color, visible: false});
  const hexColor = rgbHex(state.color.r, state.color.g, state.color.b, state.color.a);

  return (
    <div className={"color-picker"}>
      {props.label && <label>{props.label}</label>}

      <div className={"color-preview-container"}>
        <div className={"color-preview"} onClick={() => setState({...state, visible: !state.visible})} style={{backgroundColor: `#${hexColor}`}}></div>
      </div>

      {state.visible && (
        <>
          <div className={"popover"}>
            <div className={"cover"} onClick={() => setState({...state, visible: false})}></div>
            <SketchPicker color={state.color}
                          onChange={(color: ColorResult) => {
                            setState({...state, color: color.rgb});
                            props.onChange && props.onChange(color.rgb);
                          }}
            />
          </div>
        </>
      )}
    </div>
  );
}

ColorPicker.defaultProps = {
  color: "#000"
};

export default ColorPicker;