import React, {useState} from "react";
import {AdyenProvider} from "../types";
import {useTranslation} from "react-i18next";
import ProviderUsage from "./ProviderUsage";

const AdyenItem = (props: any) => {
    const [editMode, setEditMode] = useState(props.editMode ?? false);
    const [provider, setProvider] = useState<AdyenProvider>(props.provider);
    const {t} = useTranslation();
    
    if(!provider.environment)
        setProvider({...provider, environment: "TEST"});

    const onSave = () => {
        props.onSave && props.onSave(provider);
        setEditMode(false);
    }
    const onDelete = () => {
        props.onDelete && props.onDelete(provider);
    }

    const onCancel = () => {
        setProvider(props.provider);
        setEditMode(false);
    }
    return (
        <div className={"provider adyen"}>
            <div className={"provider-header"}>
                <h3>{provider.name} ({provider.type})</h3>
                <ProviderUsage usage={provider.usage ?? []}/>
                {!editMode && (
                    <>
                        <button className={"btn btn-edit hover-btn"} aria-label={t(`pick-n-pay.payment-providers.editButton`)} onClick={() => setEditMode(!editMode)}>
                            <span className="btn-text">{t(`pick-n-pay.payment-providers.editButton`)}</span>
                        </button>
                    </>)}
            </div>
            {editMode && (
                <div className={"provider-settings"}>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.name.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.name ?? ""} onChange={(e) => setProvider({...provider, name: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.name.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label>{t(`pick-n-pay.payment-providers.adyen.environment.label`)}</label>
                        <select value={provider.environment ?? "TEST"} onChange={(e) => setProvider({...provider, environment: e.target.value})}>
                            <option value={"TEST"}>{t(`pick-n-pay.payment-providers.adyen.environment.test`)}</option>
                            <option value={"LIVE"}>{t(`pick-n-pay.payment-providers.adyen.environment.live`)}</option>
                        </select>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.adyen.environment.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.adyen.apiKey.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.apiKey ?? ""} onChange={(e) => setProvider({...provider, apiKey: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.netsEasy.apiKey.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.adyen.clientKey.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.clientKey ?? ""} onChange={(e) => setProvider({...provider, clientKey: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.adyen.clientKey.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.adyen.hmacKey.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.hmacKey ?? ""} onChange={(e) => setProvider({...provider, hmacKey: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.adyen.hmacKey.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.adyen.merchantAccount.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.merchantAccount ?? ""} onChange={(e) => setProvider({...provider, merchantAccount: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.adyen.merchantAccount.description`)}</p>
                    </div>

                    <div className={"btn-group"}>
                        <button className={"btn btn-save"} onClick={onSave}>{t(`pick-n-pay.payment-providers.addProvider.saveButton`)}</button>
                        {!props.createMode && (<>
                                <button className={"btn btn-cancel"} onClick={onCancel}>{t(`pick-n-pay.payment-providers.addProvider.cancelButton`)}</button>
                                <button className={"btn btn-delete"} onClick={onDelete}>{t(`pick-n-pay.payment-providers.addProvider.deleteButton`)}</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
};

export default AdyenItem;