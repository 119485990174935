import React, {useState} from "react";
import {NetsEasyProvider} from "../types";
import {useTranslation} from "react-i18next";
import ProviderUsage from "./ProviderUsage";

const NetsEasyItem = (props: any) => {
    const [editMode, setEditMode] = useState(props.editMode ?? false);
    const [provider, setProvider] = useState<NetsEasyProvider>(props.provider);
    const {t} = useTranslation();
    
    if(!provider.jsSrc || !provider.clientMode){
        setProvider({...provider, clientMode: "Test", jsSrc: "https://test.checkout.dibspayment.eu/v1/checkout.js?v=1"}) //default values
    }

    const onSave = () => {
        props.onSave && props.onSave(provider);
        setEditMode(false);
    }
    const onDelete = () => {
        props.onDelete && props.onDelete(provider);
    }

    const onCancel = () => {
        setProvider(props.provider);
        setEditMode(false);
    }
    return (
        <div className={"provider nets-easy"}>
            <div className={"provider-header"}>
                <h3>{provider.name} ({provider.type})</h3>
                <ProviderUsage usage={provider.usage ?? []}/>
                {!editMode && (
                    <>
                        <button className={"btn btn-edit hover-btn"} aria-label={t(`pick-n-pay.payment-providers.editButton`)} onClick={() => setEditMode(!editMode)}>
                            <span className="btn-text">{t(`pick-n-pay.payment-providers.editButton`)}</span>
                        </button>
                    </>)}
            </div>
            {editMode && (
                <div className={"provider-settings"}>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.name.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.name ?? ""} onChange={(e) => setProvider({...provider, name: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.name.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label>{t(`pick-n-pay.payment-providers.netsEasy.clientMode.label`)}</label>
                        <select value={provider.clientMode} onChange={(e) => setProvider({
                            ...provider,
                            clientMode: e.target.value,
                            jsSrc: (e.target.value == "Test" ? "https://test.checkout.dibspayment.eu/v1/checkout.js?v=1" : "https://checkout.dibspayment.eu/v1/checkout.js?v=1")
                        })}>
                            <option value={"Test"}>{t(`pick-n-pay.payment-providers.netsEasy.clientMode.test`)}</option>
                            <option value={"Live"}>{t(`pick-n-pay.payment-providers.netsEasy.clientMode.live`)}</option>
                        </select>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.netsEasy.clientMode.description`)}</p>
                    </div>

                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.netsEasy.apiKey.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.apiKey ?? ""} onChange={(e) => setProvider({...provider, apiKey: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.netsEasy.apiKey.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.netsEasy.checkoutKey.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.checkoutKey ?? ""} onChange={(e) => setProvider({...provider, checkoutKey: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.netsEasy.checkoutKey.description`)}</p>
                    </div>

                    <div className={"btn-group"}>
                        <button className={"btn btn-save"} onClick={onSave}>{t(`pick-n-pay.payment-providers.addProvider.saveButton`)}</button>
                        {!props.createMode && (<>
                                <button className={"btn btn-cancel"} onClick={onCancel}>{t(`pick-n-pay.payment-providers.addProvider.cancelButton`)}</button>
                                <button className={"btn btn-delete"} onClick={onDelete}>{t(`pick-n-pay.payment-providers.addProvider.deleteButton`)}</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
};

export default NetsEasyItem;