import React from 'react';
//@ts-ignore
import {Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import './style/style.scss';
import {UserProvider, useUser} from "./UserContext";
import {SettingsProvider} from "./SettingsContext";
import Home from "./components/Home";
import Login from "./components/Login";
import ResetPassword from "./components/ResetPassword";
import SetNewPassword from "./components/SetNewPassword";
import CreateUser from "./components/CreateUser";
import Users from "./components/Users";
import EditUser from "./components/EditUser";
import Prices from "./Prices";
import PickAndPay from "./PickAndPay";
import Profile from "./Profile";
import Campaigns from "./components/PickAndPay/Campaigns";
import Stickers from "./components/PickAndPay/Stickers";
import Shops from "./components/PickAndPay/shops/Shops";
import Settings from "./components/PickAndPay/settings/Settings";
import TermsOfService from "./components/PickAndPay/TermsOfService";
import PaymentProviders from "./components/PickAndPay/payment-providers/PaymentProviders";
import Menus from "./components/PickAndPay/menus/Menus";
import EditMenu from "./components/PickAndPay/menus/EditMenu";
import CreateMenu from "./components/PickAndPay/menus/CreateMenu";
import Customers from "./components/PickAndPay/customers/Customers";
import Orders from "./components/PickAndPay/orders/Orders";
import OrderDetails from "./components/PickAndPay/orders/OrderDetails";
import CustomerDetailsPage from "./components/PickAndPay/customers/CustomerDetailsPage";
import GenerateQR from "./components/PickAndPay/generate-qr/GenerateQR";
import DeliverySpots from "./components/PickAndPay/shops/DeliverySpots";
import PrivateRoutes from "./PrivateRoutes";

const App = () => {

  return (
    <SettingsProvider>
      <UserProvider>
        <Layout>
          <Routes>
            <Route path={'/login'} element={<Login/>}/>
            <Route path={'/reset-password'} element={<ResetPassword/>}/>
            <Route path={'/reset-password/set-password'} element={<SetNewPassword/>}/>
            <Route element={<PrivateRoutes/>}>
              <Route path={'/'} element={<Home/>}/>
              <Route path={'/admin/users'}>
                <Route path={'/admin/users/'} element={<Users/>}/>
                <Route path={'create'} element={<CreateUser/>}/>
                <Route path={':userId'} element={<EditUser/>}/>
              </Route>

              <Route path={'/prices'} element={<Prices/>}>
                <Route path={':shopId'} element={<Shops/>}/>
              </Route>

              <Route path={'/pick-n-pay'}>
                <Route path={'/pick-n-pay/'} element={<PickAndPay/>}/>
                <Route path={'campaigns'} element={<Campaigns/>}/>
                <Route path={'stickers'} element={<Stickers/>}/>
                <Route path={'shops'} element={<Shops/>}>
                  <Route path={':shopId'} element={<Shops/>}>
                  </Route>
                </Route>
                <Route path={'shops/:shopId/delivery-spots'} element={<DeliverySpots/>}/>
                <Route path={'terms'} element={<TermsOfService/>}/>
                <Route path={'generate-qr'} element={<GenerateQR/>}/>
                <Route path={'/pick-n-pay/menus'}>
                  <Route path={'/pick-n-pay/menus/'} element={<Menus/>}/>
                  <Route path={'create'} element={<CreateMenu/>}/>
                  <Route path={':menuId'} element={<EditMenu/>}/>
                </Route>

                <Route path={'/pick-n-pay/customers'}>
                  <Route path={'/pick-n-pay/customers'} element={<Customers/>}/>
                  <Route path={'/pick-n-pay/customers/:customerId'} element={<CustomerDetailsPage/>}/>
                </Route>
                <Route path={'/pick-n-pay/orders'}>
                  <Route path={'/pick-n-pay/orders/'} element={<Orders/>}/>
                  <Route path={':orderId'} element={<OrderDetails/>}/>
                </Route>
                <Route path={'settings'} element={<Settings/>}>
                  <Route path={'settings'} element={<Settings/>}/>
                </Route>
                <Route path={"settings/payment-providers"} element={<PaymentProviders/>}/>

              </Route>
              <Route path={'/profile'} element={<Profile/>}/>
            </Route>
          </Routes>

        </Layout>
      </UserProvider>
    </SettingsProvider>
  );
}
export default App;