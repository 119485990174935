import {Toast, ToastContainer} from "react-bootstrap";
import React from "react";
import {useTranslation} from "react-i18next";

interface Props {
    show: boolean,
    onShowDone: () => void
    text?: string|null
    delay?: number
}
const SaveChangesMessage = (props:Props)=>{
    const {t} = useTranslation();
    const {show, onShowDone} = props;
    const [text, setText] = React.useState<string|null>(props.text ?? t('users.savedText'));
    const [delay, setDelay] = React.useState<number>(props.delay ?? 5000);
    
    return (
        <ToastContainer className={"save-message"}>
            <Toast show={show} autohide delay={delay} onClose={() => onShowDone && onShowDone()}>
                <Toast.Body>
                    <div className={"checkmark2-wrapper save-message"}>
                        <svg className={"checkmark2"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle className={"checkmark__circle"} cx="26" cy="26" r="25" fill="none"/>
                            <path className={"checkmark__check"} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                    </div>
                    <span className={"toast-text"}>{text}</span></Toast.Body>
            </Toast>
        </ToastContainer>
        
    )
};

export default SaveChangesMessage;