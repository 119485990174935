import {I18n} from "./types";

export const findI18nImageId = (i18n:I18n, locale:string):string | null | undefined =>{
  if(!i18n)
    return null;

  const trans = i18n.translations.find(x=>x.locale == locale);
  if(trans) {
    const imageId = trans.entries.find(x => x.key == "imageId")?.value;
    if(imageId){
      return imageId;
    }
  }
  return null;
}

export const setI18nImageId = (i18n:I18n, locale:string, imageId:string|null)=>{
  let loc = i18n.translations.find(x=>x.locale == locale);
  if(loc == undefined){
    loc = { locale: locale, entries: []};
    i18n.translations.push(loc);
  }
  let entry = loc.entries.find(x=>x.key == "imageId");
  if(!entry)
    loc.entries.push({key: "imageId", value: imageId});
  else
    entry.value = imageId;
}

export const hasI18nImage = (i18n:I18n, locales:string[]):boolean =>{
  if(!i18n)
    return false;
  return locales.some(l=> findI18nImageId(i18n, l) != null);
}