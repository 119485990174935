import {useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";


export interface ModalPropsBase {
  isOpen: boolean;
  onClose?: () => void;
}

interface ModalProps extends ModalPropsBase {
  children: React.ReactNode;
  showCloseButton?: boolean;
  buttons?: ModalButton[];
}

export type  ModalButton = {
  text: string,
  onClick: ()=>void
  isClose?: boolean
  className?: string
} 

const Modal = (props: ModalProps) => {
  const { isOpen, onClose, children, buttons } = props;
  const [isModalOpen, setModalOpen] = useState(isOpen);
  const modalRef = useRef<HTMLDialogElement | null>(null);

  const visibleButtons = ()=>{
    if(!props.showCloseButton){
      return buttons?.filter(f=>!f.isClose) ?? []
    }
    return buttons;
  }
  
  const handleCloseModal = () => {
    onClose && onClose();
    setModalOpen(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (event.key === "Escape") {
      handleCloseModal();
    }
  };

  useEffect(() => {
    setModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const modalElement = modalRef.current;
    if (modalElement) {
      if (isModalOpen) {
        modalElement.showModal();
      } else {
        modalElement.close();
      }
    }
  }, [isModalOpen]);

  return (
    <dialog ref={modalRef} onKeyDown={handleKeyDown}>
      <div className={"dialog-body"}>
        {children}
      </div>
      <div className={"btn-group dialog-footer"}>
        {visibleButtons()?.map((b,i)=>(
          <button key={i} className={b.className ?? "btn"} onClick={(e)=>{
            e.preventDefault();
            b.onClick && b.onClick();
            if(b.isClose){
              handleCloseModal();
            }
          }}>{b.text}</button>
        ))}
      </div>
    </dialog>
  )

}

Modal.defaultProps = {
  isOpen:false,
  showCloseButton: true,
  buttons: [
    { text: "Close", onClick: ()=>{}, isClose: true},
    { text: "Save", onClick: ()=>{}}
  ]
}

export default Modal