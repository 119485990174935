import React from 'react';
//@ts-ignore
import { Container } from 'reactstrap';
import NavMenu from "./NavMenu";


const Layout = (props:React.PropsWithChildren)=>{
    return (
      <>
        <NavMenu />
        <Container tag="main">
          {props.children}
        </Container>
      </>
    );
}
export default Layout;
