import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {cyrb53} from "../../cyrb53";
import {useTraceUpdate} from "../Dashboard/hooks/useTraceUpdate";

interface Props{
    checked:boolean;
    name:string,
    label:string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
    generateName?:boolean;
    debug?:boolean;
    disabled?:boolean;
}
const FancyCheckbox = (props:Props) => { 
  //useTraceUpdate(props,'FancyCheckbox');
  const {checked, name, label} = props;
  const [isChecked, setIsChecked] = useState(checked ?? false);
  const inputId = props.generateName ? cyrb53(name).toString():name;
  
  const checkedChanged = (e:ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
    if(props.onChange){
      props.onChange(e);
    }
  };
  
  useEffect(()=>{
    setIsChecked(checked);
  },[props.checked]);
    
  return (
    <div className={"checkbox-wrapper"}>
      {props.debug && (
        <pre>{JSON.stringify({isChecked, checked, inputId}, null, 2)}</pre>
      )}
      <input 
             id={inputId}
             type={"checkbox"}
             name={inputId} 
             checked={isChecked}
             onChange={checkedChanged}
             disabled={props.disabled}
      />
      <label className="toggle" htmlFor={inputId}>
        <span className="toggler">
          <svg width="10px" height="10px" viewBox="0 0 10 10">
            <path
              d="M5,1 L5,1 C2.790861,1 1,2.790861 1,5 L1,5 C1,7.209139 2.790861,9 5,9 L5,9 C7.209139,9 9,7.209139 9,5 L9,5 C9,2.790861 7.209139,1 5,1 L5,9 L5,1 Z"></path>
          </svg>
        </span>
      </label>
      <label htmlFor={inputId}>
        <span>{label}</span>
      </label>
    </div>
  );

};
export default FancyCheckbox;