import {useUser} from "./UserContext";
import React, {FormEvent, useEffect, useState} from "react";
import {ErrorDetail, ResponseError, User} from "./types";
import {useTranslation} from "react-i18next";
import Loading from "./components/Loading";
import SaveChangesMessage from "./components/SaveChangesMessage";

const Profile = () => {
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
    const [saveProfileSuccess, setSaveProfileSuccess] = useState(false);
    const [passwordError, setPasswordError] = useState<ResponseError | null>(null);
    const [user, setUser] = useState<User>({
        id: "",
        firstName: "",
        lastName: "",
        email: ""
    });

    const [passwordChange, setPasswordChange] = useState({
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    });

    useEffect(() => {
        fetch('/account/user')
            .then(response => response.json())
            .then((user) => {
                setUser(user);
                setLoading(false);
            });
    }, []);

    const onValueChange = (e: any) => {
        const {name, value} = e.target;
        setUser({...user, [name]: value});
    }
    const changePassword = (e: FormEvent<HTMLFormElement>) => {
        setPasswordError(null)
        e.preventDefault();
        fetch('/account/user/change-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(passwordChange)
        })
            .then(response =>{
                if(!response.ok){
                    return response.json()
                }
                return response;
            })
            .then(data => {
                if(data && data.ok) {
                    setChangePasswordSuccess(true);
                    setPasswordChange({
                        currentPassword: "",
                        newPassword: "",
                        confirmNewPassword: ""
        
                    });
                } else {
                    setChangePasswordSuccess(false)
                    setPasswordError(data as ResponseError);
                    
                }
        }).catch(error => {
            debugger;
        });
    }
    const saveProfile = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        fetch('/account/user', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstName: user.firstName,
                lastName: user.lastName
            })
        }).then(response => {
            setSaveProfileSuccess(true);
        }).catch(error => {
            debugger;
        });
    }

    return (
        <div className={"user-profile settings"}>
            <div className={"user-profile-header"}>
                <h1>{t('profile.header')}</h1>
            </div>
            {loading && <Loading/>}
            {user && (
                <> 
                    <form className={"profile-form settings-group"} onSubmit={saveProfile}>
                        <div className={"input-group "}>
                            <label htmlFor={"name"}>{t('profile.name.label')}</label>
                            <input id={"name"} name={"firstName"} type={"text"} value={user.firstName} onChange={onValueChange} required={true}/>
                        </div>

                        <div className={"input-group"}>
                            <label htmlFor={"lastName"}>{t('profile.lastName.label')}</label>
                            <input name={"lastName"} type={"text"} value={user.lastName} onChange={onValueChange} required={true}/>
                        </div>

                        <div className={"input-group"}>
                            <label htmlFor={"email"}>{t('profile.email.label')}</label>
                            <input name={"email"} type={"email"} disabled={true} value={user.email} onChange={onValueChange} required={true}/>
                        </div>
                        
                        <div className={"btn-group"}>
                            <button className={"btn btn-save"} type={"submit"}>{t('profile.save.text')}</button>
                            <SaveChangesMessage show={saveProfileSuccess} onShowDone={() => setSaveProfileSuccess(false)}/>
                        </div>
                    </form>

                    <form method={"post"} onSubmit={changePassword}>
                        <h2>{t('profile.change-password-header')}</h2>
                        <input style={{display:'none'}}  type={"text"} name={"username"} value={user.email} readOnly={true} autoComplete={"username"}/>
                        <div className={"settings-group"}>
                            <div className={"input-group"}>
                                <label htmlFor={"currentPassword"}>{t('profile.password.label')}</label>
                                <input name={"currentPassword"} type={"password"} value={passwordChange.currentPassword} required={true} autoComplete={"current-password"}
                                    onChange={(e) => setPasswordChange({...passwordChange, currentPassword: e.target.value})}/>
                            </div>
                            <div className={"input-group"}>
                                <label htmlFor={"newPassword"}>{t('profile.newPassword.label')}</label>
                                <input name={"newPassword"} type={"password"} value={passwordChange.newPassword} required={true} autoComplete={"new-password"}
                                    onChange={(e) => setPasswordChange({...passwordChange, newPassword: e.target.value})}/>
                            </div>
                            <div className={"input-group"}>
                                <label htmlFor={"confirmPassword"}>{t('profile.confirmPassword.label')}</label>
                                <input name={"confirmPassword"} type={"password"} value={passwordChange.confirmNewPassword} required={true} autoComplete={"new-password"}
                                    onChange={(e) => setPasswordChange({...passwordChange, confirmNewPassword: e.target.value})}/>
                            </div>
                            
                            <button className={"btn btn-save"} type={"submit"}>{t('profile.changePassword.text')}</button>

                            {passwordError &&(
                                <div className="alert alert-danger" role="alert">
                                    <h3>{t("set-password.errors.header")}</h3>
                                    {passwordError.errors && (
                                        <ul>
                                            {passwordError.errors.map((err:ErrorDetail)=>(
                                                <li key={err.key}>
                                                    {t(`set-password.errors.${err.key}`)}
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            
                            <SaveChangesMessage show={changePasswordSuccess} onShowDone={() => setChangePasswordSuccess(false)}/>
                            
                        </div>
                    </form>


                    <section className={"user-information"}>
                        <h2>{t('profile.profitCenters.header')}</h2>
                        <ul className={"user-profitcenters"}>
                            {user.profitCenters && user.profitCenters.map((profitCenter) => (
                                <li key={profitCenter.code}>{profitCenter.name}</li>
                            ))}
                        </ul>
                        <h2>{t('profile.roles.header')}</h2>
                        <ul>
                            {user.roles && user.roles.map((role) => (
                                <li key={role}>{t(`users.roles.${role}`)}</li>
                            ))}
                        </ul>
                    </section>
                </>
            )}
        </div>
    )
};
export default Profile;