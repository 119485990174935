import React, {useEffect, useState} from "react";
import Select, {ActionMeta} from "react-select";
import Loading from "../../Loading";
import {useTranslation} from "react-i18next";
import {Cashier, CustomerGroup, DiscountGroup, PaymentMethod} from "../types";
import Picker, {Option, OptionValue} from "./Picker";
import ButtonConfirm from "./ButtonConfirm";
import FancyCheckbox from "../FancyCheckbox";
import SaveChangesMessage from "../../SaveChangesMessage";

export type OrderType = "TakeAway" | "DineInPlace"
export type PnpSettings = {
  siteUrl: string,
  orderTypes: OrderType[] | { value: OrderType, label: string }[],
  shopRedList: string[],
  enabledLanguages: string[] | { value: string, label: string }[],
  enabledPaymentProviders: string[] | { value: string, label: string }[],
  winpos: WinposSettings
  email: EmailSettings,
  webPush: WebPushSettings,
  enableCustomerLogin: boolean,
  deploymentMode: string | { value: string, label: string },
  authentication: AuthenticationSettings
}
export type WinposSettings = {
  paymentNumber: number,
  customerCodePrefix: string,
  customerDiscountGroup: string | OptionValue,
  customerGroup: string | OptionValue,
  cashierNumber: number | OptionValue,
  defaultCurrency: string | OptionValue,
}

export type EmailSettings = {
  defaultSender?: string | null | undefined,
  defaultSenderName?: string | null | undefined,
}

export type WebPushSettings = {
  vapidSubject: string,
  vapidPublicKey: string,
  vapidPrivateKey: string,
}

export type AuthenticationSettings = {
  facebook: {
    appId: string |null,
    appSecret?: string |null,
  },
  google: {
    clientId: string |null,
    clientSecret: string |null,
  },
}


const Settings = () => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState<boolean>(true);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [state, setState] = useState<PnpSettings | null>(null);
  const [email, setEmail] = useState<EmailSettings | null>(null);
  const [auth, setAuth] = useState<AuthenticationSettings | null>(null);
  const [winpos, setWinpos] = useState<WinposSettings | null>(null);
  const [webPush, setWebPush] = useState<WebPushSettings | null>(null);
  
  const [cashiers, setCashiers] = useState<Option[]>([]);
  const [customerGroups, setCustomerGroups] = useState<Option[]>([]);
  const [discountGroups, setDiscountGroups] = useState<Option[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<Option[]>([]);

  useEffect(() => {
    if (state !== null)
      return;

    setLoading(true)
    const promises = [
      fetch('api/pick-n-pay/settings')
        .then(response => response.json())
        .then(data => {
          const result = data as PnpSettings;
          result.enabledLanguages = result.enabledLanguages.map((l: string) => {
            return {value: l, label: t(`common.languages.${l}`)};
          });
          result.enabledPaymentProviders = result.enabledPaymentProviders.map((l: string) => {
            return {value: l, label: t(`common.payment-providers.${l}`)};
          });
          result.orderTypes = result.orderTypes.map((l: OrderType) => {
            return {value: l, label: t(`pick-n-pay.settings.orderTypes.${l}`)};
          });
          
          result.deploymentMode = { value: result.deploymentMode, label: t(`pick-n-pay.settings.deploymentModes.${result.deploymentMode}`)}
          result.winpos.defaultCurrency = { value : result.winpos.defaultCurrency, label: result.winpos.defaultCurrency}
          
          setState(result);
          setEmail(result.email);
          setWinpos(result.winpos);
          setWebPush(result.webPush);
          setAuth(result.authentication);
        }),

      fetch("/api/pick-n-pay/settings/cashiers")
        .then(response => response.json())
        .then(data => {
          const cashiers = data as Cashier[];
          setCashiers(cashiers.map(c =>{ return { value: c.code, label: c.name}}))
        }),

      fetch("/api/pick-n-pay/settings/customer-groups")
        .then(response => response.json())
        .then(data => {
          const groups = data as CustomerGroup[];
          setCustomerGroups(groups.map(c =>{ return { value: c.code, label: c.name}}));
        }),

      fetch("/api/pick-n-pay/settings/discount-groups")
        .then(response => response.json())
        .then(data => {
          const groups = data as DiscountGroup[];
          setDiscountGroups(groups.map(c =>{ return { value: c.code, label: c.name}}));
        }),

      fetch("/api/pick-n-pay/settings/payment-methods")
        .then(response => response.json())
        .then(data => {
          const methods = data as PaymentMethod[];
          setPaymentMethods(methods.map(c =>{ return { value: c.number, label: c.name}}));
        })
    ];
    Promise.all(promises).then(() => {
      setLoading(false);
    });

  });

  if (loading) {
    return (<Loading visible={true}/>)
  }

  const languageOptions = [
    {value: 'en', label: t('common.languages.en')},
    {value: 'sv', label: t('common.languages.sv')},
    {value: 'fi', label: t('common.languages.fi')},
    {value: 'no', label: t('common.languages.no')},
  ];

  const paymentProviderOptions = [
    {value: 'SwedbankPay', label: t('common.payment-providers.SwedbankPay')},
    {value: 'NetsEasy', label: t('common.payment-providers.NetsEasy')},
    {value: 'Stripe', label: t('common.payment-providers.Stripe')},
    //{value: 'Adyen', label: t('common.payment-providers.Adyen')},
  ];

  const orderTypeOptions = [
    {value: 'TakeAway', label: t(`pick-n-pay.settings.orderTypes.TakeAway`)},
    {value: 'DineInPlace', label: t(`pick-n-pay.settings.orderTypes.DineInPlace`)}
  ];

  const deploymentModeOptions = [
    {value: 'Local', label: t(`pick-n-pay.settings.deploymentModes.Local`)},
    {value: 'Remote', label: t(`pick-n-pay.settings.deploymentModes.Remote`)}
  ];
  
  const currencyOptions = [
    { value: "SEK", label: "SEK" }, 
    { value: "NOK", label: "NOK" }, 
    { value: "DKK", label: "DKK" }, 
    { value: "EUR", label: "EUR" }
  ];

  const onSave = () => {
    const settings = {
      ...state,
      email: {...email},
      webPush: {...webPush},
      winpos: {...winpos},
      authentication: {...auth}
    };
    
    settings.enabledPaymentProviders = settings.enabledPaymentProviders.map((l: { value: string, label: string }) => l.value);
    settings.enabledLanguages = settings.enabledLanguages.map((l: { value: string, label: string }) => l.value);
    settings.orderTypes = settings.orderTypes.map((l: { value: string, label: string }) => l.value);
    settings.deploymentMode = settings.deploymentMode.value;
    settings.winpos.defaultCurrency = winpos?.defaultCurrency.value;
    
    fetch('api/pick-n-pay/settings', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(settings),
    }).then(response => {
      if (response.ok) {
        setSaveSuccess(true);
      }
    });
  }

  const onChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {
    setState({
      ...state,
      [actionMeta.name]: option
    });
  };
  const onWinposChange = (option: readonly Option[], actionMeta: ActionMeta<Option>) => {
    setWinpos({
      ...winpos,
      [actionMeta.name]: option
    });
  };

  const onCashierChanged = (cashierId: OptionValue) => {
    setWinpos({
      ...winpos,
      cashierNumber: cashierId as number
    })
  };

  const onCustomerGroupChanged = (customerGroupid: OptionValue) => {
    setWinpos({
      ...winpos,
      customerGroup: customerGroupid ?? ""
    });
  };

  const onDiscountGroupChanged = (discountGroupId: OptionValue) => {
    setWinpos({
      ...winpos,
      customerDiscountGroup: discountGroupId 
    });
  };
  const onPaymentMethodChanged = (methodId: OptionValue) => {
    setWinpos({
      ...winpos,
      paymentNumber: methodId as number
    });
  }


  return (
    <div className={"pick-n-pay settings"}>
      <h1>{t(`pick-n-pay.settings.header`)}</h1>
      
      <section className={"card-section"}>
        <h3>{t(`pick-n-pay.settings.sections.general`)}</h3>
        <div className={"card-group"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.siteUrl.label`)}</label>
            <input className={"form-control"} type={"text"} value={state?.siteUrl ?? ""} onChange={(e) => setState({...state, siteUrl: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.siteUrl.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.languages.label`)}</label>
            <Select name={"enabledLanguages"} onChange={onChange} defaultValue={state?.enabledLanguages} className={"form-control"} options={languageOptions} isMulti={true}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.languages.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.orderTypes.label`)}</label>
            <Select name={"orderTypes"} onChange={onChange} defaultValue={state?.orderTypes} options={orderTypeOptions} isMulti={true}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.orderTypes.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.enabledPaymentProviders.label`)}</label>
            <Select name={"enabledPaymentProviders"} onChange={onChange} defaultValue={state?.enabledPaymentProviders} className={"form-control"} options={paymentProviderOptions} isMulti={true}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.enabledPaymentProviders.description`)}</p>
          </div>

          {/*<div className={"input-group"}>*/}
          {/*  <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.deploymentModes.label`)}</label>*/}
          {/*  <Select className={"select-deployment-mode"} name={"deploymentMode"} onChange={onChange} defaultValue={state?.deploymentMode} options={deploymentModeOptions}*/}
          {/*          isMulti={false}/>*/}
          {/*  <p className={"form-info"}>{t(`pick-n-pay.settings.deploymentModes.description`)}</p>*/}
          {/*</div>*/}
        </div>
      </section>
      <section className={"card-section"}>
        <h3>{t(`pick-n-pay.settings.sections.authentication.header`)}</h3>
        <p>{t(`pick-n-pay.settings.sections.authentication.description`)}</p>
        <div className={"card-group"}>
          <div className={"input-group"}>
            <label>{t(`pick-n-pay.settings.enableCustomerLogin.label`)}</label>
            <FancyCheckbox checked={state?.enableCustomerLogin ?? true} name={"enableCustomerLogin"} label={t(`pick-n-pay.settings.enableCustomerLogin.activate`)} onChange={(e) => {
              setState({...state, enableCustomerLogin: e.target.checked})
            }}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.enableCustomerLogin.description`)}</p>
          </div>

          {state?.enableCustomerLogin && (
        <>
          <h4>{t(`pick-n-pay.settings.sections.authentication.facebook`)}</h4>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.facebook.appId.label`)}</label>
            <input className={"form-control"} type={"text"} value={auth?.facebook.appId ?? ""}
                  onChange={(e) => setAuth({...auth, facebook: {...auth?.facebook, appId: e.target.value}})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.facebook.appId.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.facebook.appSecret.label`)}</label>
            <input className={"form-control"} type={"password"} value={auth?.facebook.appSecret ?? ""}
                  onChange={(e) => setAuth({...auth, facebook: {...auth?.facebook, appSecret: e.target.value}})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.facebook.appSecret.description`)}</p>
          </div>

          <h4>{t(`pick-n-pay.settings.sections.authentication.google`)}</h4>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.google.clientId.label`)}</label>
            <input className={"form-control"} type={"text"} value={auth?.google.clientId ?? ""}
                  onChange={(e) => setAuth({...auth, google: {...auth?.google, clientId: e.target.value}})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.google.clientId.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.google.clientSecret.label`)}</label>
            <input className={"form-control"} type={"password"} value={auth?.google.clientSecret ?? ""}
                  onChange={(e) => setAuth({...auth, google: {...auth?.google, clientSecret: e.target.value}})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.google.clientSecret.description`)}</p>
          </div>
        </>
          )}
        </div>
      </section>
      <section className={"card-section"}>
        <h3>{t(`pick-n-pay.settings.sections.email`)}</h3>
        <div className={"card-group"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.defaultSender.label`)}</label>
            <input className={"form-control"} type={"email"} value={email?.defaultSender ?? ""} onChange={(e) => setEmail({...email, defaultSender: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.defaultSender.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.defaultSenderName.label`)}</label>
            <input className={"form-control"} type={"text"} value={email?.defaultSenderName ?? ""} onChange={(e) => setEmail({...email, defaultSenderName: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.defaultSenderName.description`)}</p>
          </div>
        </div>
      </section>
      <section className={"card-section"}>
        <h3>{t(`pick-n-pay.settings.sections.winpos`)}</h3>

        <div className={"card-group"}>
          <div className={"input-group"}>
            <Picker label={t(`pick-n-pay.settings.paymentMethod.label`)} value={winpos?.paymentNumber ?? null} options={paymentMethods}
                    onChange={onPaymentMethodChanged}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.paymentMethod.description`)}</p>
          </div>

          <div className={"input-group"}>
            <Picker options={cashiers} label={t(`pick-n-pay.settings.cashier.label`)}
                    value={winpos?.cashierNumber.toString() ?? ""}
                    onChange={onCashierChanged}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.cashier.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.customerCodePrefix.label`)}</label>
            <input className={"form-control"} type={"text"} value={winpos?.customerCodePrefix ?? ""} onChange={(e) => setWinpos({...winpos, customerCodePrefix: e.target.value})}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.customerCodePrefix.description`)}</p>
          </div>
          
          <div className={"input-group"}>
            <Picker label={t(`pick-n-pay.settings.discountGroup.label`)} value={winpos?.customerDiscountGroup ?? ""} options={discountGroups}
                    onChange={onDiscountGroupChanged}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.discountGroup.description`)}</p>
          </div>

          <div className={"input-group"}>
            <Picker label={t(`pick-n-pay.settings.customerGroup.label`)} value={winpos?.customerGroup.toString() ?? ""} options={customerGroups}
                    onChange={onCustomerGroupChanged}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.customerGroup.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.settings.defaultCurrency.label`)}</label>
            <Select name={"defaultCurrency"} onChange={onWinposChange} defaultValue={state?.winpos.defaultCurrency} options={currencyOptions} isMulti={false}/>
            <p className={"form-info"}>{t(`pick-n-pay.settings.defaultCurrency.description`)}</p>
          </div>

        </div>
      </section>

      <div className={"form-footer"}>
        <ButtonConfirm title={t(`pick-n-pay.settings.save.description`)} 
                      className={"btn btn-save"} 
                      message={t(`pick-n-pay.settings.save.confirmText`)} 
                      onConfirm={onSave}>
          {t(`pick-n-pay.settings.save.label`)}
        </ButtonConfirm>
        <SaveChangesMessage show={saveSuccess} onShowDone={()=>setSaveSuccess(false)} />
      </div>
    </div>
  );
}

export default Settings;