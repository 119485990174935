import {useEffect, useState} from "react";

export const usePnpLocales = () => {
   const [locales, setLocales] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {
        setLoading(true);
        fetch('api/pick-n-pay/settings')
            .then(response => response.json())
            .then((data)=>{
                setLocales(data.enabledLanguages);
                setLoading(false);
            });
    }, []);
    const getLocaleOrDefault = (currentLocale:string)=>{
      console.log('currentLocale', currentLocale,'locales', locales);
      if(locales.includes(currentLocale)){
        return currentLocale;
      }
      return locales[0];
    };
    return { locales, loading, getLocaleOrDefault};
};