import React, {ChangeEvent, useEffect, useState} from "react";
import {ArticleResult, PaginatedResult, Sticker, StickerType} from "./types";
import FancyCheckbox from "./FancyCheckbox";
// @ts-ignore
import StickerPicker from "./StickerPicker";
import Loading from "../Loading";
import {findI18nImageId} from "./functions";
import {useSettings} from "../../SettingsContext";
import {useUser} from "../../UserContext";
import Login from "../Login";
import {useNavigate} from "react-router-dom";
import { t } from "i18next";
import {usePnpLocales} from "./hooks/usePnpLocales";

interface Props {
  article: ArticleResult
  locales?: string[]
}

const SearchResultItem = (props: Props) => {
  const [article, setArticle] = useState<ArticleResult>(props.article);
  const {settings} = useSettings();
  const onStickersChanged = (stickers: Sticker[]) => {
    setArticle({
      ...article,
      stickers: stickers
    });
  };

  return (
    <>
      <h1>{article.name}</h1>
      <span className={"department"}>{article.subDepartmentName}</span>
      
      <span className={"article-id"}>{article.id}</span>
      <ul className={"article-stickers"}>
        {article.stickers?.map(s => (
          <li key={s.id}>
            {!findI18nImageId(s.i18n, settings.locale!) && <span className={"sticker-placeholder"}>{s.name}</span>}
            {findI18nImageId(s.i18n,settings.locale!) && <img title={s.name} className={"sticker-image"} src={`/api/pick-n-pay/images/${findI18nImageId(s.i18n, settings.locale!)}`} alt={s.name}/>}
          </li>
        ))}
      </ul>
      <StickerPicker type={StickerType.Article} target={article.id} stickers={article.stickers} locales={props.locales} onChange={onStickersChanged}/>
    </>
  );
}

interface State {
  items: ArticleResult[],
  pageIndex?: number,
  pageSize?: number,
  totalCount?: number,
  categories: string[]
}

const Stickers = () => {
  
  const [state, setState] = useState<State>({
    items: [],
    categories: []
  });
  const {locales} = usePnpLocales();
  const [loading, setLoading] = useState<boolean>(true);
  const [filter, setFilter] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [onlyWithStickers, setOnlyWithStickers] = useState<boolean>(false);
  const [filteredItems, setFilteredItems] = useState<ArticleResult[]>([]);
  const {user} = useUser();
  const navigate = useNavigate();
  
  useEffect(() => {
    if(!user){
      navigate('/');
      return;
    }
    setLoading(true)
    fetch('api/pick-n-pay/articles?pageIndex=0&pageSize=1000')
      .then(r => r.json())
      .then(data => {
        const result: PaginatedResult<ArticleResult> = data;
        const categories = result.items
          .map(i => i.subDepartmentName)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort();

        setState({
          ...state,
          ...result,
          categories
        });
        setFilteredItems(result.items);
        setLoading(false);
      });
  }, []);

  if(!user){
    navigate('/');
  }
  
  const filterChanged = (e: ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
    filterItems(query, e.target.value, onlyWithStickers);
  }
  const queryChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    setQuery(e.target.value);
    filterItems(e.target.value, filter, onlyWithStickers);
  }
  const onlyWithStickersChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    console.log('onlyWithStickersChanged', e.target.checked);
    setOnlyWithStickers(e.target.checked);
    filterItems(query, filter, e.target.checked);
  }
  
  
  const resetForm = ()=>{
    setQuery("");
    setFilter("");
    filterItems(null, null,false);
  }
  
  const filterItems = (text:string|null, category:string|null, onlyWithStickers:boolean)=>{
    let filtered = state.items;
    if(text){
      filtered = filtered.filter(i=>i.name.toLowerCase().includes(text.toLowerCase()));
    }
    if(category){
      filtered = filtered.filter(i=>i.subDepartmentName.toLowerCase().includes(category.toLowerCase()));
    }
    if(onlyWithStickers){
      filtered = filtered.filter(i=>{
        return i.stickers && i.stickers.length > 0;
      });
    }
    
    setFilteredItems(filtered);
  }

  return (
    <div className={"pick-n-pay stickers"}>
      <div className={"filter-container"}>
        <h1>{t('pick-n-pay.stickers.header')}</h1>
        <form className={"filters"} onReset={resetForm}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.stickers.search')}</label>
            <input className={"form-control"} type={"search"} onChange={queryChanged}/>
          </div>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.stickers.category.label')}</label>
            <select className={"form-select"} onChange={filterChanged}>
              <option value={""}>{t('pick-n-pay.stickers.category.all')}</option>
              {state.categories?.map(c => (
                <option key={c}>{c}</option>
              ))}
            </select>
          </div>
          <FancyCheckbox checked={onlyWithStickers} onChange={onlyWithStickersChanged} name={"onlyStickered"} label={t('pick-n-pay.stickers.showOnlyStickers')}/>
          <button type={"reset"} className={"btn"}>{t('pick-n-pay.stickers.clear')}</button>
        </form>
      </div>
      {loading && <Loading visible={true}/>}
      <div className="search-result-container">
        <ol className={"result-container search-result"}>
          {filteredItems.map(article => (
            <li className={"article"} key={article.id}>
              <SearchResultItem article={article} locales={locales}/>
            </li>
          ))}
        </ol>
      </div>
    </div>
  )
}

export default Stickers;