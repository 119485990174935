import {useState} from "react";
//@ts-ignore
import {useTranslation} from "react-i18next";
//@ts-ignore
import {ProfitCenterArea} from "../types";

interface Props {
  profitCenterAreas: ProfitCenterArea[];
  selectedProfitCenterArea?: ProfitCenterArea|null;
  onProfitCenterAreaSelected: (profitCenterArea: ProfitCenterArea | null)=>void;
}

const ProfitCenterAreaPicker = (props:Props)=>{
  const { profitCenterAreas, selectedProfitCenterArea, onProfitCenterAreaSelected } = props;
  const {t} = useTranslation();
  
  if(!profitCenterAreas.find(f=>f.code === null))
    profitCenterAreas.unshift({code: null, description: t('profitcenter-area-picker.all')});
  
  const [selected, setSelected ]= useState(selectedProfitCenterArea);
  
  const selectProfitCenterArea = (pc:ProfitCenterArea) =>{
    if(!pc.code){
      pc = null;
    }
    setSelected(pc);
    if(onProfitCenterAreaSelected)
      onProfitCenterAreaSelected(pc);
  }
  
  const isSelected = (pc:ProfitCenterArea)=> {
    if(selected != null) {
      if(selected?.code === pc.code)
        return true;
      return false;
    }

    return profitCenterAreas[0].code === pc.code;
  }
  return (
      <div className={"row profitcenter-area-picker"}>
        <label className={"filter-label"}>{t('profitcenter-area-picker.areas')}</label>
        {profitCenterAreas.map((pc) => (
            <button className={'btn btn-picker ' + (isSelected(pc) ? 'selected' : '')} onClick={()=>selectProfitCenterArea(pc)} key={pc.code}>
              {pc.description}
            </button>
        ))}
      </div>
  )
}
export default ProfitCenterAreaPicker