import {useSettings} from "../../SettingsContext";
import React, {ChangeEvent, useEffect, useState} from "react";
import {Campaign, I18n, Sticker, StickerType, hasI18nLocale, setI18n} from "./types";
//@ts-ignore
import {useTranslation} from "react-i18next";
import LocaleSelector from "./LocaleSelector";
import I18nInput, {I18nSpan} from "../../I18nInput";
import TextEditor from "../TextEditor";
import ImageUpload from "./ImageUpload";
import StickerPicker from "./StickerPicker";
import ButtonConfirm from "./settings/ButtonConfirm";
import {findI18nImageId, hasI18nImage, setI18nImageId} from "./functions";
import Picker, {Option} from "./settings/Picker";
import FancyCheckbox from "./FancyCheckbox";
import {usePnpLocales} from "./hooks/usePnpLocales";

interface Props {
  campaign: Campaign,
  locales:string[]
}

export const CampaignRow = (props: Props) => {
  const {locales} = props;
  const {settings} = useSettings();
  const [loc, setLoc] = useState<string>(settings.locale ?? "en");
  const [campaign, setCampaign] = useState<Campaign>(props.campaign);
  const [campaignItems, setCampaignItems] = useState<Option[]>([]);
  
  const [initialContent, setInitialContent] = useState<string>(campaign?.description);
  const [description2, setDescription2] = useState<string>();
  const [image, setImage] = useState<string | null| undefined>(findI18nImageId(campaign.i18n!, loc));
  const [state, setState] = useState({
    expanded: false,
    editMode: false,
  });
  const {t} = useTranslation();
  const toggle = () => {
    if (state.editMode)
      return;
    
    const url = campaign.id !== null 
      ? `/api/pick-n-pay/campaigns/${campaign.id}` 
      : `/api/pick-n-pay/campaigns/code/${campaign.code}`;
    
    fetch(url)
      .then(response => response.json())
      .then((data:Campaign)=>{
        setCampaign(data);
        setCampaignItems(data.items.map((item)=>{ return { label: item.codeTargetName, value: item.code}}));
        
        setState({
          ...state,
          expanded: !state.expanded
        });
    });
  }
  
  useEffect(()=>{
    setImage(findI18nImageId(campaign.i18n!, loc));
  },[loc])

  const onCreate = () => {
    fetch("/api/pick-n-pay/campaigns", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({id: campaign.code}),
    }).then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Error creating campaign");
    }).then(data => {
      setI18n(data.i18n!, "name", loc,campaign.winposName);
      setCampaign(data);
      setState({
        ...state,
        editMode: true
      })
    })
  };

  const onDelete = () => {
    fetch(`/api/pick-n-pay/campaigns/${campaign.id}`, {
      method: "DELETE"
    }).then(response => {
      if (!response.ok)
        throw new Error("Error deleting campaign");
    })
  }

  const onEdit = () => {
    setState({
      ...state,
      editMode: true
    });
  }
  
  const onCancelEdit = () => {
    setCampaign(props.campaign);
    setState({
      ...state,
      editMode: false
    });
  }

  const onSave = () => {
    console.log("save", campaign);
    const camp = {
      ...campaign,
      description: description2,
    }

    fetch(`/api/pick-n-pay/campaigns/${campaign.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(camp),
    }).then(response => {
      if (response.ok)
        return response.json();
      throw new Error("Error updating campaign");
    }).then(data => {
      setImage(findI18nImageId(data.i18n!, loc));
      setState({
        ...state,
        editMode: false
      });
      setCampaign({
        ...data,
        description: description2 ?? ""
      });
    });
  }
  const onImageUploaded = (imageId: string | null) => {
    if (state.editMode) {
      const i18n = campaign.i18n;
      if(!hasI18nImage(i18n!, settings.supportedLocales)) {
        //set the same image for all locales
        settings.supportedLocales.forEach(l => {
          setI18nImageId(i18n!, l, imageId!);
        });
      } else {
        //only update the image for the current locale
        setI18nImageId(i18n!, loc, imageId!);
      }
      setCampaign({
        ...campaign,
        i18n: i18n
      });
      console.log(i18n);
    }
  }

  const updateI18n = (i18n: I18n | null) => {
    setCampaign({
      ...campaign,
      i18n: i18n
    });
  }
  
  const onStickerApplied = (stickers:Sticker[])=>{
    setCampaign({
      ...campaign,
       stickers: stickers
    });
  }

  const isEmpty = (str: string | null | undefined) => {
    if(!str) return true;
    return str.trim() === "";
  }
  

  return (
    <>
      <div className={"campaign-header " + (state.expanded ? "expanded" : "")}>
        <button className={"btn-expander"} area-label={t('pick-n-pay.campaigns.expand')} onClick={toggle}>
          <span className={"sr-only"}>{t('pick-n-pay.campaigns.expand')}</span>
        </button>
        <h3 onClick={toggle}>
          {isEmpty(campaign.winposName)?campaign.code:campaign.winposName}
          <span className={"campaign-code"}>{campaign.code}</span>
        </h3>
        <LocaleSelector disabled={state.editMode} onChange={(locale) => setLoc(locale)} locales={locales} locale={loc} i18n={campaign.i18n}/>
        <span className={'system-activated ' + (campaign.winposEnabled ? "enabled" : "")}>
          {t('pick-n-pay.campaigns.winpos-activated')}
        </span>
        <span className={'system-activated ' + (campaign.enabled ? "enabled" : "disabled")}>
          {t('pick-n-pay.campaigns.visible-online')}
        </span>
      </div>
      {state.expanded && (
        <div className={"campaign-details" + (state.editMode ? ' edit-mode ' : ' ') + (hasI18nLocale(campaign.i18n!, loc) ? '' : 'i18n-empty')}>
          <div className={"campaign-column"}>
            {state.editMode && (
              <form onSubmit={(e) => e.preventDefault()}>
                <div className={"input-group"}>
                  <label className={"form-label input-group-text"}>{t('pick-n-pay.campaigns.name')}</label>
                  <I18nInput i18n={campaign.i18n} i18nKey={"name"} locale={loc} onChange={updateI18n} defaultValue={campaign.winposName}/>
                </div>

                <div className={"input-group"}>
                  <label className={"form-label input-group-text"}>{t('pick-n-pay.campaigns.description')}</label>
                  <TextEditor initialContent={initialContent} initialContentI18n={campaign.i18n} locale={loc} i18nKey={"description"}
                              onChange={(content, contentHtml, updatedI18n) => {
                                //logI18n(updatedI18n!);
                                  updateI18n(updatedI18n!);
                              }}/>
                </div>
              </form>
            )}
            {!state.editMode && (
              <>
                <b>{t('pick-n-pay.campaigns.name')}</b>
                <p><I18nSpan i18n={campaign.i18n} i18nKey={"name"} locale={loc} fallback={campaign.winposName}/></p>
                <b>{t('pick-n-pay.campaigns.description')}</b>
                <p><I18nSpan i18n={campaign.i18n} i18nKey={"description"} locale={loc} html={true} /></p>
              </>
            )}
          </div>
          <div className={"campaign-column"}>

            <div className={"campaign-image"}> 
              {!state.editMode && campaign.id !== null && image && (
                <img className={"selected-image"} src={`/api/pick-n-pay/images/${image}`} alt={"Campaign image"}/>
              )}
              {!state.editMode && campaign.id !== null && !image && (
                <div className={"dummy-image"}></div>
              )}
              
              {state.editMode && campaign.id !== null && (
                <ImageUpload currentImage={image} onImageUploaded={onImageUploaded}/>
              )}
              {state.editMode && (
                <span className={"upload-info"}>{t('pick-n-pay.campaigns.imageUploadInfo')}</span>
              )}
            </div>
          </div>

          <div className={"campaign-column"}>
            
            <label>{t('pick-n-pay.campaigns.campaignItems')}</label>
            <ul className={"campaign-affected-items"}>
              {campaign.items.filter(i=>i.type !== "ArticleGroupArticleCode").map((item,idx) => (
                <li className={"campaign-affected-item"} key={idx}>
                  <span className={"item-type"}>{item.type}</span>
                  <code>{item.code}</code>
                  <span className={"code-target-name"}>{item.codeTargetName}</span>
                </li>
              ))}
            </ul>
            <label>{t('pick-n-pay.campaigns.campaignConditions')}</label>
            <ul className={"campaign-conditions"}>
            {campaign.conditions.map((item,idx) => (
                <li className={"campaign-condition"} key={idx}>
                  <span className={"condition-type"}>{t(item.type)}</span>
                  {item.conditionDisplayText !== "" && (
                    <span className={"condition-display-text"}>{item.conditionDisplayText}</span>
                  )}
                  {item.conditionDisplayText === "" && (
                    <span className={"condition-string"}>{item.conditionString}</span>
                  )}
                </li>
              ))}
            </ul>

            <Picker label={t(`pick-n-pay.campaigns.articleCodeLink`)} 
                    value={campaign.articleCodeLink ?? ""} 
                    options={campaignItems}
                    editable={state.editMode}
                    onChange={(option)=>setCampaign({...campaign, articleCodeLink:option as string})} />
            
            <div className={"stickers"}>
              <label>{t('pick-n-pay.stickers.header')}</label>
              <ul className={"campaign-stickers"}>
                {campaign.stickers?.map(sticker => (
                  <li className={"campaign-sticker"} key={sticker.id}>
                    <img className={"sticker-image"} src={`/api/pick-n-pay/images/${findI18nImageId(sticker.i18n, settings.locale!)}`} alt={"Sticker"}/>
                  </li>
                ))}
              </ul>
              {state.editMode && (
                <StickerPicker type={StickerType.Campaign} target={campaign.id} stickers={campaign.stickers} onChange={onStickerApplied} />
              )}
            </div>
          </div>
          <div className={"actions campaign-footer"}>
            {!campaign.id && (
              <div className={"create-campaign"}>
                <button onClick={onCreate} className={"btn btn-create hover-btn"} area-label={t('pick-n-pay.campaigns.create')}>
                  <span className="btn-text">{t('pick-n-pay.campaigns.create')}</span>
                </button>
              </div>
            )}

            {campaign.id && (
              <>
                {!state.editMode && (
                  <button className={"btn btn-primary btn-edit"} area-label={t('pick-n-pay.campaigns.edit')} onClick={onEdit}>
                    <span className="btn-text">{t('pick-n-pay.campaigns.edit')}</span>
                  </button>
                )}
                {state.editMode && (
                  <div className={"btn-group"}>
                    <FancyCheckbox checked={campaign.enabled} name={"enabled"} label={t('pick-n-pay.campaigns.enabled')} onChange={(e)=>{
                      setCampaign({...campaign, enabled:e.target.checked});
                    }} />
                    <ButtonConfirm area-label={t('pick-n-pay.campaigns.delete.description')} className={"btn btn-delete hover-btn"} message={t('pick-n-pay.campaigns.delete.confirmText')} onConfirm={onDelete}>
                      <span className="btn-text">{t('pick-n-pay.campaigns.delete.label')}</span>
                    </ButtonConfirm>
                    <button onClick={onCancelEdit} className={"btn btn-cancel"} area-label={t('pick-n-pay.campaigns.cancel')}>{t('pick-n-pay.campaigns.cancel')}</button>
                    <button onClick={onSave} className={"btn btn-save"} area-label={t('pick-n-pay.campaigns.save')}>{t('pick-n-pay.campaigns.save')}</button>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  )
}