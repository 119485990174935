import React, {ChangeEvent, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {SelectProfitCenter} from "./SelectProfitCenter";
import {Button, Form, FormControl, Toast, ToastContainer} from "react-bootstrap";
import useForceUpdate from "../useForceUpdate";
import Modal from "react-bootstrap/Modal";
import {ProfitCenter, RoleNames, User} from "../types";
import FancyCheckbox from "./PickAndPay/FancyCheckbox";
import {useTranslation} from "react-i18next";
import Loading from "./Loading";
import {useSettings} from "../SettingsContext";

interface Params {
  userId: string
}

interface State {
  initialName: string,
  user: User,
  profitCenters: ProfitCenter[],
}

const EditUser = () => {
  const {settings} = useSettings();
  const navigate = useNavigate();
  const {t}= useTranslation();
  const {userId} = useParams<Params>();
  const forceUpdate = useForceUpdate();
  const [loading, setLoading] = useState(true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);
  
  const initialRoles =[
    { role: RoleNames.Administrator, assigned: false},
    { role: RoleNames.GlobalAccess, assigned: false},
  ] 
  
  if(settings.showPriceListInterface){
    initialRoles.push({ role: RoleNames.PriceListManager, assigned: false});
  }
  
  if(settings.showWebOrderInterface) {
    initialRoles.push({ role: RoleNames.PickAndPayManager, assigned: false});
  }
  
  const [roles, setRoles] = useState<any[]>(initialRoles);
  
  const [state, setState] = useState<State>({
      initialName: "",
      user: {firstName: "", lastName: "", id: "", email: "", isAdministrator: false, globalAccess: false, profitCenters: [], priceListAccess: false, webOrderManager: false},     
      profitCenters: [],
    }
  );

  useEffect(() => {
    setLoading(true)
    Promise.all(
      [
        fetch('/api/profitcenters').then(response => response.json()),
        fetch(`/api/users/${userId}`).then(response => response.json())
      ]).then(([profitcenters, user]) => {
      setState({
        ...state,
        profitCenters: profitcenters,
        user: user,
        initialName: `${user.firstName} ${user.lastName}`
      });
      const userAssignments = roles.map(r => {
        return {role: r.role, assigned: user.roles.includes(r.role)};
      });
      setRoles(userAssignments);
      setLoading(false);
    });
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    setState({
      ...state,
      user: {
        ...state.user,
        [name]: value
      }
    });
  }

  const submit = (event: SubmitEvent) => {
    event.preventDefault();
    
    const body = {
      ...state.user,
      roles: roles.filter(r => r.assigned).map(r => r.role)
    };
    
    fetch(`/api/users/${userId}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }).then(r => {
      if(r.ok) {
        setSaveSuccess(true);
      } else {
        alert(r.statusText)
      }
      //success
    });
  }
  const deleteUser = (doDelete: boolean) => {
    if (!doDelete) {
      setShowDeleteConfirm(true);
      return;
    }

    fetch(`/api/users/${userId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(() => {
      setShowDeleteConfirm(false);
      navigate("/admin/users");
    })
  };

  const selectedProfitCentersChanged = () => {
    forceUpdate();
  };

  if(loading) {
    return <Loading visible={true} />
  }

  const roleChanged = (e:ChangeEvent<HTMLInputElement>) => {
    setRoles(roles.map(r => {
      if(r.role === e.target.name){
        return {role: r.role, assigned: e.target.checked}
      }
      return r;
    }));
  };
  
  return (
    <div className={"admin-page edit-user"}>
      <h1>{t('users.editHeader')}{state.initialName}</h1>

      <Form className={"form user-info"}>
        <div className={"input-group"}>
          <label>{t('users.email')}</label>
          <FormControl disabled={true} name={"email"} type={"email"} value={state.user.email}
                       onChange={handleChange}/>
        </div>
        <div className={"input-group"}>
          <label>{t('users.firstname')}</label>
          <FormControl name={"firstName"} type={"text"} value={state.user.firstName} onChange={handleChange}/>
        </div>

        <div className={"input-group"}>
          <label>{t('users.lastname')}</label>
          <FormControl name={"lastName"} type={"text"} value={state.user.lastName} onChange={handleChange}/>
        </div>

        {roles && roles.map((role)=>(
          <FancyCheckbox key={role.role} checked={role.assigned} name={role.role} label={t(`users.roles.${role.role}`)} onChange={roleChanged} />
        ))}

        <div className={"btn-group"}>
          <button type={"button"} className={"btn btn-danger mx-3"} onClick={() => deleteUser(false)}>{t('users.removeButton.label')}</button>
          <Button type={"submit"} className={"btn btn-primary"} onClick={submit}>{t('users.saveButton')}</Button>
          
          <ToastContainer position={"top-center"}>
            <Toast show={saveSuccess} autohide delay={40000} onClose={() => setSaveSuccess(false)}>
              <Toast.Body>
                <div className={"checkmark2-wrapper"}>
                  <svg className={"checkmark2"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className={"checkmark__circle"} cx="26" cy="26" r="25" fill="none"/>
                    <path className={"checkmark__check"} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                  </svg>
                </div>
                <span className={"toast-text"}>{t('users.savedText')}</span></Toast.Body>
            </Toast>
          </ToastContainer>
        </div>
      </Form>
      <div className={"user-profitcenter-access"}>
        <h2>{t('users.profitcenterSelector.header')}</h2>

        <ul className={"list"}>
          {state.user.profitCenters.map(pc => (
            <li key={pc.code} className={"list-group-item"}>
              <span key={pc.code}>{pc.name}</span>
            </li>
          ))}
        </ul>

        <SelectProfitCenter profitCenters={state.profitCenters}
                            selectedProfitCenters={state.user.profitCenters} onClose={selectedProfitCentersChanged}/>
      </div>


      <Modal show={showDeleteConfirm} centered={true} dialogClassName={"confirm-dialog"}>
        <Modal.Header closeButton>
          <Modal.Title>{t('users.removeButton.label')}{state.user.firstName} {state.user.lastName}?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('users.removeButton.dialogText')}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>{t('users.removeButton.noButton')}</Button>
          <Button variant="primary" onClick={() => deleteUser(true)}>{t('users.removeButton.yesButton')}</Button>
          
        </Modal.Footer>
      </Modal>


    </div>


  )
}

export default EditUser;