import MenuBuilder from "./MenuBuilder";
import ArticleSearch from "./ArticleSearch";
import {useNavigate, useParams} from "react-router-dom";
import {ArticleMenuItem, Menu, MenuItemTypes} from "./Menus";
import React, {useEffect, useState} from "react";
import Loading from "../../Loading";
import SaveChangesMessage from "../../SaveChangesMessage";
import {useTranslation} from "react-i18next";
import {ResponseError} from "../../../types";

interface Params {
  menuId: string;
}

const EditMenu = () => {
  const {menuId} = useParams<Params>();
  const [menu, setMenu] = useState<Menu | null>(null);
  const [menuName, setMenuName] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ResponseError | null>(null);
  const [saveSuccess, setSaveSuccess] = useState<boolean>(false);
  const [usedArticles, setUsedArticles] = useState<string[]>([]);
  const navigate = useNavigate();
  const {t} = useTranslation();
  const isNew = () => {
    return menu?.id === 0;
  }

  useEffect(() => {
    if (menuId === 'new') {
      setLoading(false);
      setMenu({
        id: 0,
        name: 'New Menu',
        items: [],
        shops: [],
      });
      setMenuName('New Menu');
    } else {
      setLoading(true)
      setError(null);
      fetch('api/pick-n-pay/menus/' + menuId)
        .then(r => {
          let json = r.json();
          if(r.ok)
            return json;
          return json.then(Promise.reject.bind(Promise));
        })
        .then((data) => {
          setLoading(false);
          const theMenu = data as Menu;
          setMenu(theMenu);
          setMenuName(theMenu!.name);
        })
        .catch((response:ResponseError) => {
          setError(response);
          setLoading(false)
          setMenu(null);
          setMenuName('');
        });
    }

  }, [menuId])


  const onSave = (menu: Menu) => {
    menu.name = menuName;
    if (isNew()) {
      fetch('api/pick-n-pay/menus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(menu)
      }).then(r => r.json())
        .then((data) => {
          const m = data as Menu;
          navigate(`/pick-n-pay/menus/${m.id}`, {replace: true});
        })
    } else {
      fetch(`api/pick-n-pay/menus/${menu.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(menu)
      }).then(() => {
        setSaveSuccess(true);
      })
    }
  }

  const onDelete = (menu: Menu) => {
    fetch(`api/pick-n-pay/menus/${menu.id}`, {
      method: 'DELETE'
    }).then(() => {
      navigate('/pick-n-pay/menus', {replace: true});
    });
  }

  if (loading) {
    return (<Loading visible={true}/>);
  }

  return (
    <div className={"pick-n-pay menu-editor-page"}>
      <h1>
        {t('pick-n-pay.menus.edit-menu.header', {name: menuName})}
      </h1>
      {error && (
        <div className={"alert alert-danger"}>
          <span>{t(error.title)}</span>
        </div>
      )}
      {menu && (
        <>
          <div className={"input-group menu-name"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.menus.edit-menu.name.label')}</label>
            <input className={"form-control"} type={"text"} value={menuName} onChange={(e) => {
              setMenuName(e.target.value);
            }}/>
            <SaveChangesMessage show={saveSuccess} onShowDone={() => setSaveSuccess(false)}/>
          </div>
          <div className={"menu-editor"}>
            <ArticleSearch usedArticleIds={usedArticles}/>
            <MenuBuilder menu={menu} onSave={onSave} onDelete={onDelete} onItemsChanged={(items) => {
              const articleIds = items.filter(i => i.type == MenuItemTypes.Article)
                .map(i => (i as ArticleMenuItem).code);

              setUsedArticles(articleIds);
            }}/>
          </div>
        </>
      )}

    </div>
  )
}

export default EditMenu;