import React from "react";
const LabelValue = (props:{ label:string, value:string, valueClassName?:string})=>{
  const {label, value, valueClassName} = props;
  return (
    <div className={"label-group"}>
      <span className={"label"}>{label}</span>
      <span className={valueClassName}>{value}</span>
    </div>
  )
}

export default LabelValue