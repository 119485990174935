import React from "react";
import {DateTime} from "luxon";
import {useSettings} from "../../../SettingsContext";
import {useFormatters} from "../../formatters";
import {Link} from "react-router-dom";
//@ts-ignore
import {useTranslation} from "react-i18next";
import {TransactionHeader} from "../types";
interface Props {
  order: TransactionHeader;
  showCustomer?: boolean;
}

const OrderRow = (props:Props)=>{
  const {t} = useTranslation();
  const {settings} = useSettings();
  const formatters = useFormatters(settings)
  const {order} = props;
  return (
    <li className={"order-result"}>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.orders.list-headers.created')}</span>
        <span>{DateTime.fromISO(order.creationTime, { zone: 'UTC' }).toLocal().toFormat('yyyy-MM-dd HH:mm')}</span>
      </div>
      <div className="result-item">
      <span className="item-name">{t('pick-n-pay.orders.list-headers.orderNr')}</span>
        <span>{order.orderNr}</span>
      </div>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.orders.list-headers.status')}</span>
        <span>{t(`pick-n-pay.orders.status.${order.status.toLowerCase()}`)}</span>
      </div>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.orders.list-headers.type')}</span>
        <span>{t(`pick-n-pay.orders.type.${order.orderType.toLowerCase()}`)}</span>
      </div>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.orders.list-headers.shop')}</span>
        <span>{order.shop?.name}</span>
      </div>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.orders.list-headers.customer')}</span>
        {props.showCustomer &&(
          <>
          {order.customer && (<Link to={`/pick-n-pay/customers/${encodeURIComponent(order.customer.id)}`}>{order.customer?.name} {order.customer?.lastName}</Link>)}
          {!order.customer && (<span className={"anonymous"}>{t(`pick-n-pay.orders.customer.anonymous`)}</span>)}
          </>
        )}
      </div>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.orders.list-headers.total')}</span>
        <span>{formatters.currency.format(order.total)}</span>
      </div>
      <div className={"btn-group result-item"}>
        <Link to={`/pick-n-pay/orders/${order.guid}`} className={"btn"}>
          {t(`pick-n-pay.orders.list.details.text`)}
        </Link>
      </div>
    </li>
  )
}
OrderRow.defaultProps = {
  showCustomer: true
};

export default OrderRow;