import React, {ChangeEvent, useEffect, useState} from "react";
import ImageUpload from "../ImageUpload";
import {PaymentProvider, Shop, Terminal} from "../types";
import {useTranslation} from "react-i18next";
import {PaginatedResult} from "../../../types";
import Loading from "../../Loading";
import Picker, {Option, OptionValue} from "../settings/Picker";
import ButtonConfirm from "../settings/ButtonConfirm";
import ShopStatus from "../widgets/ShopStatus";
import {Menu} from "../menus/Menus";
import {ModalLocationPicker} from "../../ModalLocationPicker";

interface Props {
  shop:Shop
}

interface State {
  editMode:boolean,
  description:string,
  name:string,
  terminalId:number,
  externalAppUri:string,
  longitude:number,
  latitude:number,
  merchantId:string,
  imageId:string|null
  paymentProviderId:number|null
  menuId:number|null
}

const getDefaultState = (shop:Shop):State => ({
  editMode: false,
  description: shop.description ??"",
  name: shop.name ??"",
  terminalId: shop.terminalId,
  externalAppUri: shop.externalAppUri ??"",
  longitude: shop.location.longitude,
  latitude: shop.location.latitude,
  merchantId: shop.merchantId,
  imageId: shop.imageId,
  paymentProviderId: shop.paymentProviderId,
  menuId: shop.menu?.id
});

const ShopInfo = (props:Props) => {
  const [shop, setShop] = useState<Shop>(props.shop);
  const [state, setState] = useState(getDefaultState(props.shop));
  const [terminals, setTerminals] = useState<Option[]>([]);
  const [menus, setMenus] = useState<Option[]>([]);
  const [paymentProviders, setPaymentProviders] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  
  useEffect(()=>{
    setShop(props.shop);
    setState(getDefaultState(props.shop));
  },[props.shop]);
  
  const {t} = useTranslation();
  
  useEffect(()=>{
    setLoading(true);
    
    const promises = [
      fetch("/api/pick-n-pay/terminals?pageIndex=0&pageSize=1000")
        .then(response=>response.json())
        .then(data=>{
          const page = data as PaginatedResult<Terminal>;
          setTerminals(page.items.map(t=>({ value: t.id, label: t.name })));
        }),
      
      fetch("/api/pick-n-pay/payment-providers")
        .then(response=>response.json())
        .then(data=>{
          const providers = data as PaginatedResult<PaymentProvider>;
          setPaymentProviders(providers.items.map(p=>({ value: p.id, label: `${p.name} (${p.type})` })));
        }),
      fetch("/api/pick-n-pay/menus")
        .then(response=>response.json())
        .then(data=>{
          const menus = data as Menu[];
          setMenus(menus.map(m=>({ value: m.id, label: m.name })));
        })
    ];
    Promise.all(promises)
      .then(()=>setLoading(false));
  },[]);
  
  const onInputChanged = (e:ChangeEvent<HTMLInputElement>) => {
    setState({...state, [e.target.name]: e.target.value});
  }
  const onTerminalChanged = (terminalId:OptionValue)=>{
    setState({...state, terminalId:terminalId as number});
  }
  
  const onPaymentProviderChanged = (providerId:OptionValue)=>{
    setState({...state, paymentProviderId:providerId as number});
  }
  const onSelectedMenuChanged = (menuId:OptionValue)=>{
    setState({...state, menuId:menuId as number});
  }
  
  const onCancel = ()=>{
    setState(getDefaultState(props.shop));
  }
  const onSave = ()=>{
    const updatedShop = {
      ...shop,
      name: state.name,
      description: state.description,
      externalAppUri: state.externalAppUri,
      location: {
        longitude: state.longitude,
        latitude: state.latitude
      },
      merchantId: state.merchantId,
      terminalId: state.terminalId,
      imageId: state.imageId,
      paymentProviderId: state.paymentProviderId,
      menu: menus.filter(m=>m.value === state.menuId)
        .map(m=>({id: m.value, name: m.label}))
        .find(m=>m.id === state.menuId)
    }
    
    fetch(`/api/pick-n-pay/shops/${updatedShop.id}`,{
      method: "PUT",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(updatedShop)
    })
      .then(response=>response.json())
      .then(data=>{
        setShop(updatedShop);
        setState({...state, editMode:false});
      });
  }
    const onImageUploaded = (imageId:string|null) => {
        setState({...state,imageId: imageId});
    }
  
  if(loading){
    return (
    <div className={"shop-info"}>
      <Loading visible={true}/> 
    </div>
    )
  }
  
  return (
    <div className={"shop-info"}>
      <h4>
        {t('pick-n-pay.shops.shop-info.header')} {shop.description} 
        <button className={"btn btn-edit hover-btn"} area-label={t('pick-n-pay.shops.shop-info.edit')} onClick={()=>setState({...state,editMode:true})}>
          <span className="btn-text">{t('pick-n-pay.shops.shop-info.edit')}</span>
        </button>
      </h4>
      <div className={"shop-status"}>
        <ShopStatus shop={shop} />
      </div>
      <div>
          {state.editMode && (
            <ImageUpload currentImage={shop.imageId} onImageUploaded={onImageUploaded}/>
          )}
          {!state.editMode && (
              <div className={"shop-image"}>
              {shop.imageId && (<img src={`/api/pick-n-pay/images/${shop.imageId}`} alt={"Shop image"} />)}
              {!shop.imageId && (<div className={"dummy-image"}></div>)}
              </div>
          )}
        <div className={"shop-location"}>

          <ModalLocationPicker
            showEditButton={state.editMode} 
            location={{latitude: state.latitude, longitude: state.longitude}}
            isOpen={false} onLocationChange={(e)=>{
            if(e) {
              console.log(e);
              setState({...state, latitude: e.latitude, longitude: e.longitude});
            }
          }} />
        </div>

        <Picker label={t('pick-n-pay.shops.shop-info.terminal')} value={shop.terminalId} options={terminals} editable={state.editMode} 
                onChange={onTerminalChanged}/>

        <Picker label={t('pick-n-pay.shops.shop-info.paymentProvider')} value={shop.paymentProviderId} options={paymentProviders} editable={state.editMode}
                onChange={onPaymentProviderChanged}/>

        <Picker label={t('pick-n-pay.shops.shop-info.menu')} value={shop.menu?.id} options={menus} editable={state.editMode}
                onChange={onSelectedMenuChanged}/>
        
        {!state.editMode && (
          <>
            <div className={"input-group"}>
              <span className={"label"}>{t('pick-n-pay.shops.shop-info.profitCenter')}</span>
              <span className={"value"}>{shop.id}</span>
            </div>
            <div className={"input-group"}>
              <span className={"label"}>{t('pick-n-pay.shops.shop-info.merchantId')}</span>
              <span className={"value"}>{shop.merchantId}</span>
            </div>
            <div className={"input-group"}>
              <span className={"label"}>{t('pick-n-pay.shops.shop-info.externalUrl')}</span>
              <span className={"value"}>{shop.externalAppUri ?? "not set"}</span>
            </div>
          </>
        )}
        {state.editMode && (
          <>
            <div className={"input-group"}>
              <label className={"form-label input-group-text"}>{t('pick-n-pay.shops.shop-info.profitCenter')}</label>
              <span className={"form-control"}>{shop.id}</span>
            </div>

            <div className={"input-group"}>
              <label className={"form-label input-group-text"}>{t('pick-n-pay.shops.shop-info.merchantId')}</label>
              <input className={"form-control"} name={"merchantI§d"} type={"text"} value={state.merchantId} onChange={onInputChanged}/>
            </div>

            <div className={"input-group"}>
              <label className={"form-label input-group-text"}>{t('pick-n-pay.shops.shop-info.externalUrl')}</label>
              <input className={"form-control"} name={"externalAppUri"}  type={"text"} value={state.externalAppUri} onChange={onInputChanged}/>
            </div>

            
          </>
        )}
        
        

      </div>

      {state.editMode && (
        <div className={"form-footer btn-group"}>
          <button className={"btn btn-cancel"} onClick={onCancel}>{t('pick-n-pay.shops.shop-info.cancel')}</button>
          <ButtonConfirm title={t('pick-n-pay.shops.shop-info.save.description')} className={"btn btn-save"} message={t('pick-n-pay.shops.shop-info.save.confirmText')} onConfirm={onSave}>
            {t('pick-n-pay.shops.shop-info.save.label')}
          </ButtonConfirm>
          
        </div>
      )}
    </div>)
}

export default ShopInfo;