import {PaymentProviderUsage} from "../types";
import React from "react";
import {useTranslation} from "react-i18next";

const ProviderUsage = (props: { usage: PaymentProviderUsage[] }) => {
  const {t} = useTranslation();

  const limit = 5;
  return (
    <>
      <ul className={"provider-usage"}>
        {props.usage.slice(0, limit).map(usage => (
          <li className={"badge"} key={usage.shopId}>{usage.shopName}</li>
        ))}
      </ul>
      {props.usage.length > limit && <span>{t('pick-n-pay.payment-providers.usageCount', {count: (props.usage.length - limit)})}</span>}
    </>)
}
export default ProviderUsage;
