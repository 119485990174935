import {useEffect, useState} from "react";
import firstDayOfPeriod from "../../firstDayOfPeriod";
import moment from "moment/moment";

export const useArticleStatistics = (props) => {

  const [articleStats, setArticleStats] = useState([]);
  const [articleCompareStats, setArticleCompareStats] = useState([]);
  const [articleStatStatus, setArticleStatStatus] = useState({
    type:'article',
    status: '',
    compareStatus: ''
  });

  useEffect(() => {
    setArticleStats([]);
    setArticleCompareStats([]);
    setArticleStatStatus((prev)=> {
      return {...prev, status: 'loading', compareStatus: 'loading'};
    });

    let formattedPeriod = firstDayOfPeriod(props.period, props.periodType).format("YYYYMMDD");
    let qs = `profitCenterId=${props.profitCenter?.code ?? ""}`;
    if (props.profitCenter == null && props.profitCenterArea != null) {
      qs = `areaId=${props.profitCenterArea?.code ?? ""}`;
    }

    let url = '';
    if (props.period)
      url = `/api/sdpt-sales?${qs}&periodType=${props.periodType}&period=${formattedPeriod}&count=${props.count}`;
    else if (props.from && props.to) {
      const formattedFrom = moment(props.from).format("YYYYMMDD");
      const formattedTo = moment(props.to).format("YYYYMMDD");

      url = `/api/sdpt-sales?${qs}&periodType=${props.periodType}&from=${formattedFrom}&to=${formattedTo}&count=${props.count}`;
    }

    if (url === '') {
      setArticleStatStatus({...articleStatStatus, status: 'done', compareStatus: 'done'});
      return;
    }

    const promises = [];
    promises.push(fetch(url)
      .then(response => response.json())
      .then(result => {
        setArticleStats(result.sales);
        setArticleStatStatus((prev)=>{
          return {...prev, status: 'done'};
          }
        );
      }));

    if (props.compare || (props.compareFrom && props.compareTo)) {
      let compareUrl = '';
      if (props.compare) {
        const formattedCompare = firstDayOfPeriod(props.compare, props.periodType).format("YYYYMMDD");
        compareUrl = `/api/sdpt-sales?${qs}&periodType=${props.periodType}&period=${formattedCompare}&count=${props.count}`;
      } else if (props.compareFrom && props.compareTo) {
        const formattedCompareFrom = moment(props.compareFrom).format("YYYYMMDD");
        const formattedCompareTo = moment(props.compareTo).format("YYYYMMDD");

        compareUrl = `/api/sdpt-sales?${qs}&periodType=${props.periodType}&from=${formattedCompareFrom}&to=${formattedCompareTo}&count=${props.count}`;
      }

      promises.push(fetch(compareUrl)
        .then(response => response.json())
        .then(result => {
          setArticleCompareStats(result.sales);
          setArticleStatStatus((prev)=>{
            return {...prev, compareStatus: 'done'}
          });
        }));
    } else {
      setArticleCompareStats([]);
      setArticleStatStatus((prev)=>{
        return {...prev, compareStatus: 'done'}
      });
    }

    Promise.all(promises).then(() => {
      setArticleStatStatus((prev)=>{
        return {...prev,status: 'done', compareStatus: 'done'}
      });
    });     
  }, [props.period, props.periodType, props.profitCenter, props.profitCenterArea, props.compare, props.from, props.to, props.compareFrom, props.compareTo]);

  const getArticleStatsInGroup = (group) => {
    let qs = `profitCenterId=${props.profitCenter?.code ?? ""}`;
    if (props.profitCenter == null && props.profitCenterArea != null) {
      qs = `areaId=${props.profitCenterArea?.code ?? ""}`;
    }

    const dptCode = group.code;

    if (props.period) {
      const formattedPeriod = firstDayOfPeriod(props.period, props.periodType).format("YYYYMMDD");
      return fetch(`/api/plu-sales?${qs}&periodType=${props.periodType}&period=${formattedPeriod}&count=5000&dptCode=${dptCode}`)
        .then((response) => response.json())
        .then((data) => {
          return data;
        });
    } else {
      return fetch(`/api/plu-sales?${qs}&periodType=Day&from=${moment(props.from).format("YYYYMMDD")}&to=${moment(props.to).format("YYYYMMDD")}&count=5000&dptCode=${dptCode}`)
        .then((response) => response.json())
        .then((data) => {
          return data;
        });
    }

  }

  return {articleStats, articleCompareStats, articleStatStatus, getArticleStatsInGroup};
}
