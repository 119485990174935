import { t } from "i18next";
import {ChangeEvent, createElement, useEffect, useRef, useState} from "react";

interface Props {
  uploadEndpoint?:string|null;
  onImageUploaded?: (imageId:string|null)=>void;
  currentImage?:string|null;
  renderMode?:string;
}
interface State {
  showImage:boolean;
  currentImage?:string|null;
}
const ImageUpload = (props:Props) => {
  let {renderMode} = props;
  if(!renderMode) {
    renderMode = "form";
  }
  let  {uploadEndpoint} = props;
  if(!uploadEndpoint){
    uploadEndpoint = "/api/pick-n-pay/images";
  }
  const  [state, setState] = useState<State>({
    showImage: props.currentImage != null,
    currentImage: props.currentImage
  });
  
  useEffect(()=>{
    setState({
      showImage: props.currentImage != null,
      currentImage: props.currentImage
    });
  }, [props.currentImage]);
  
  const input = useRef<HTMLInputElement>(null)
  const dropArea = useRef(null);
  
  const uploadClick = (e:any) => {
    input.current?.click();
    e.preventDefault();
  }
  const uploadFile = (e:ChangeEvent<HTMLInputElement>) => {
    if(input.current != null && input.current.files != null){
      const file = input.current.files[0];
      sendFile(file).then(imageId=>{
        showImage(imageId)
        if(props.onImageUploaded){
          props.onImageUploaded(imageId);
        }
      });
    }
    e.preventDefault();
  };

  const onFileDropped = (e:any)=> {
    const file = e.dataTransfer.files[0];
    sendFile(file).then(imageId=>{
      showImage(imageId)
        if(props.onImageUploaded){
            props.onImageUploaded(imageId);
        }
    });
    e.preventDefault();
  }
  
  const onDragOver = (e:DragEvent) => {
    const target = e.target as HTMLDivElement;
    if(target.classList.contains("drop-area") && !target.classList.contains("drag-over")){
      target.classList.add("drag-over");
    }
    
    e.preventDefault();
  };
  
  const onDragLeave = (e:any)=>{
    const target = e.target as HTMLDivElement;
    if(target.classList.contains("drop-area") && target.classList.contains("drag-over")){
      target.classList.remove("drag-over");
    }
    e.preventDefault();
  }
  
  const sendFile  = async (file: File) :Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    let response = await fetch(uploadEndpoint!, {
      method: "POST",
      body: formData
    });
    return response.json();
  }

  const showImage = (imageId:string)=>{
    setState({
      ...state,
      showImage: true,
      currentImage: imageId
    });
  }

  const onClear = ()=>{
    setState({
      ...state,
      showImage: false,
      currentImage: undefined
    });
    props.onImageUploaded && props.onImageUploaded(null);
  }

  

  return (
    <div className={"image-upload" +(state.currentImage?' has-image':'')}>
      <div ref={dropArea} className={"drop-area"} onDrop={onFileDropped} onDragOver={onDragOver} onDragLeave={onDragLeave}>
          <Element tag={renderMode} className={"form-or-div"}>
          <div className={"drop-info"}>
            <span>{t('components.imagePicker.dragHere')}</span>
            <span>{t('components.imagePicker.or')}</span>
            <button type={"button"} onClick={uploadClick} className={"btn"}>{t('components.imagePicker.selectImage')}</button>
          </div>
          {state.showImage && state.currentImage &&(<>
            <img alt={"The image you are uploading"} src={`/api/pick-n-pay/images/${state.currentImage}`} />
              <button className={"btn-clear-image"} type={"button"} onClick={onClear}>{t('components.imagePicker.clear')}</button>
            </>
          )}
          <input ref={input} onChange={uploadFile} type={"file"} name={"file"} id={"file"} className={"input-file"} hidden/>
          </Element>
      </div>
    </div>
  )
};

interface ElementProps {
  tag?: string;
  children: any;
  className?:string|undefined;
}
function Element(props: ElementProps) {
  return createElement(
    props.tag || 'div',
    { className: props.className },
    props.children
  );
}
export default ImageUpload;