import React, {ChangeEvent, useState} from "react";
import {DateTime} from "luxon";
import FancyCheckbox from "../FancyCheckbox";
import {OpeningHours} from "../types";
import {useSettings} from "../../../SettingsContext";
import {useTranslation} from "react-i18next";
import {getDayNames} from "../../firstDayOfPeriod";
import { t } from "i18next";

interface Props{
    day:OpeningHours,
    mode: "default" | "overrides",
    onChange:(idx:number, day:OpeningHours)=>void,
    idx:number
}

export const OpeningHoursRow = (props:Props) => {
  const {day, mode} = props;
  const {settings} = useSettings();
  const [state, setState] = useState({
    opens: day.opens,
    closes: day.closes,
    closed: day.closed
  });
  const {locale}  = settings;

  const onTimeChanged = (e:ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name;
    const value = e.target.value;
    setState({...state,[targetName]: value});     
    props.onChange(props.idx,{...day,[targetName]: value});   
  }
  
  const closedChanged = (e:ChangeEvent<HTMLInputElement>)=>{
    setState({...state,closed: !e.target.checked});
    props.onChange(props.idx, {...day,closed: !e.target.checked});     
  };
  
  const getId = ():string=>{
    return Math.floor(Math.random() * Date.now()).toString()
  }

  const localizedDayNames = getDayNames(locale);
  const dayNames = getDayNames().map(d=>d.toLowerCase())
  
  //debugger;
  let label = localizedDayNames[dayNames.indexOf(day.dayOfWeek.toLowerCase())];
  if(mode === "overrides"){
      if(day.date) {
          //indicating we are generating hours for less than a week
          label =  DateTime.fromJSDate(day.date).toFormat("yyyy-MM-dd - cccc", {
              locale: settings.locale
          })
      } else {
          
         label = localizedDayNames[dayNames.indexOf(day.dayOfWeek.toLowerCase())]; 
      }
  }

  console.log('Props opens/closes', props.day.opens,props.day.closes);
  return (
    <>
      <FancyCheckbox label={label} name={`${day.dayOfWeek}-${getId()}`} checked={!state.closed} onChange={closedChanged}/>
      <div className={"btn-group set-time"}>
        <input name={"opens"} type={"time"} value={state.opens} onChange={onTimeChanged}/>
        <label>{t('pick-n-pay.shops.hours.fromToSpacingLabel')}</label>
        <input name={"closes"} type={"time"} value={state.closes} onChange={onTimeChanged}/>
      </div>
      {state.closed && <span className={"closed"}>{t('pick-n-pay.shops.hours.closed')}</span>}
    </>
  );
}

export default OpeningHoursRow;