import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {SwedBankPayProvider} from "../types";
import ProviderUsage from "./ProviderUsage";

const SwedBankItem  = (props:any) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState(props.editMode ?? false);
    const [provider, setProvider] = useState<SwedBankPayProvider>(props.provider);
    if(!provider.apiBaseUrl){
        setProvider({...provider, apiBaseUrl: "https://api.externalintegration.payex.com"});
    }

    const onSave = ()=>{
        props.onSave && props.onSave(provider);
        setEditMode(false);
    }

    const onDelete = ()=>{
        props.onDelete && props.onDelete(provider);
    }

    const onCancel = ()=>{
        setProvider(props.provider);
        setEditMode(false);
    }
    return (
        <div className={"provider swedbank-pay"}>
            <div className={"provider-header"}>
                <h3>{provider.name} ({provider.type})</h3>
                <ProviderUsage usage={provider.usage ?? []}/>
                {!editMode && (
                    <>
                        <button className={"btn btn-edit hover-btn"} aria-label={t(`pick-n-pay.payment-providers.editButton`)} onClick={() => setEditMode(!editMode)}>
                            <span className="btn-text">{t(`pick-n-pay.payment-providers.editButton`)}</span>
                        </button>
                    </>)}
            </div>

            {editMode && (
                <div className={"provider-settings"}>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.name.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.name ?? ""} onChange={(e) => setProvider({...provider, name: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.name.description`)}</p>
                    </div>

                    <div className={"input-group"}>
                        <label>{t(`pick-n-pay.payment-providers.swedbankPay.apiBaseUrl.label`)}</label>
                        <select value={provider.apiBaseUrl} onChange={(e) => setProvider({...provider, apiBaseUrl: e.target.value})}>
                            <option value={"https://api.externalintegration.payex.com"}>{t(`pick-n-pay.payment-providers.swedbankPay.apiBaseUrl.test`)}</option>
                            <option value={"https://api.payex.com"}>{t(`pick-n-pay.payment-providers.swedbankPay.apiBaseUrl.live`)}</option>
                        </select>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.swedbankPay.apiBaseUrl.description`)}</p>
                    </div>

                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.swedbankPay.payeeId.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.payeeId ?? ""} onChange={(e) => setProvider({...provider, payeeId: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.swedbankPay.payeeId.description`)}</p>
                    </div>
                    <div className={"input-group"}>
                        <label className={"form-label input-group-text"}>{t(`pick-n-pay.payment-providers.swedbankPay.token.label`)}</label>
                        <input className={"form-control"} type={"text"} value={provider.token ?? ""} onChange={(e) => setProvider({...provider, token: e.target.value})}/>
                        <p className={"form-info"}>{t(`pick-n-pay.payment-providers.swedbankPay.token.description`)}</p>
                    </div>

                    <div className={"btn-group"}>
                        <button className={"btn btn-save"} onClick={onSave}>{t(`pick-n-pay.payment-providers.addProvider.saveButton`)}</button>
                        {!props.createMode && (<>
                                <button className={"btn btn-cancel"} onClick={onCancel}>{t(`pick-n-pay.payment-providers.addProvider.cancelButton`)}</button>
                                <button className={"btn btn-delete"} onClick={onDelete}>{t(`pick-n-pay.payment-providers.addProvider.deleteButton`)}</button>
                            </>
                        )}
                    </div>
                </div>
            )}

        </div>
    )
};
export default SwedBankItem;