import CustomerDetails from "./CustomerDetails";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Loading from "../../Loading";

interface Params {
  customerId: string;
}

const CustomerDetailsPage = ()=>{
  const {customerId} = useParams<Params>();
  const [loading, setLoading] = useState(true);
  const[customer, setCustomer] = useState<any | null>(null);
  
  useEffect(()=>{
    fetch(`api/pick-n-pay/customers/${customerId}`).then(r=>r.json()).then(data=>{
      setCustomer(data);
      setLoading(false);
    });
    
  },[]);
  
  return (
    <div className={"pick-n-pay customer-details-page"}>
      {loading && <Loading visible={true}/>}
      {!loading && customer && <CustomerDetails linkToCustomer={false} customer={customer} />}
    </div>
  )
};

export default CustomerDetailsPage;