import React, {useEffect} from 'react';
import {useUser} from "./UserContext";
import {Route, Navigate, Outlet} from "react-router-dom";

const PrivateRoutes = () : Route=>{
  const userValue = useUser();
  // useEffect(()=>{
  //   if(!userValue.user){
  //     console.log("User not logged in - redirecting to login page");
  //   }
  //   else {
  //     console.log("User is logged in");
  //   }
  // },[userValue.user, userValue.loading]);
  
  if(userValue.loading){ 
    console.log('user profile is loading, waiting for it to finish');
    return <Outlet/>; 
  }
  
  return (userValue.user ? <Outlet/> : <Navigate to={'/login'}/>);
}

export default PrivateRoutes;