import React, {useEffect, useRef, useState} from "react";
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from "react-leaflet";
import {GeoSearchControl, OpenStreetMapProvider} from "leaflet-geosearch";
import {Location} from "PickAndPay/types";
import {Map, LocationEvent, Icon} from "leaflet";
import {t} from "i18next";

interface LocationPickerProps {
  location?: Location | null;
  onLocationChange?: (location: Location|null) => void;
}

const LocationPicker = (props: LocationPickerProps) => {
  const [position, setPosition] = useState<Location|null|undefined>(props.location);
  const map = useRef<Map>(null);
  
  useEffect(() => {
    if (!document.getElementById("leaflet-style")) {
      const style = document.createElement('link');
      style.id = "leaflet-style";
      style.rel = "stylesheet";
      style.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
      style.integrity = "sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ==";
      style.crossOrigin = "";
      document.head.append(style)
    }

    if (!document.getElementById("leaflet-script")) {
      const script = document.createElement('script');
      script.id = "leaflet-script";
      script.src = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.js";
      script.integrity = "sha512-gZwIG9x3wUXg2hdXF6+rVkLF/0Vi9U8D2Ntg4Ga5I5BZpVkVxlJWbSQtXPSiUTtC0TjtGOmxa1AJPuV0CPthew==";
      script.crossOrigin = "";
      document.head.append(script)
    }

    if(isEmpty(position)){
      navigator.geolocation.getCurrentPosition((position) => {
        const loc = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        };
        setPosition(loc);
        console.log('geoLocation',loc);
      });
    }
  }, []);

  const isEmpty = (location:Location|null|undefined):boolean => {
    if(!location)
      return true;
    
    if(!location.latitude)
      return true;
    
    if(!location.longitude)
      return true;
    return false;
  }

  const onMarkerPositionChanged = (e:Location|null)=>{
    setPosition(e);
    props.onLocationChange && props.onLocationChange(e);
  }
  
  const getDefaultCenter = ():[number,number] => {
    let result:[number, number];
    
    if(!isEmpty(position))
      result = [position!.latitude, position!.longitude];
    else {
      // Default to Winpos Office in Umeå for some reason
      result =  [63.84217510310813, 20.226892556317594];
    }
    console.log('getDefaultCenter', result);
    return result!;
  }
  
  map.current?.once('focus',()=>{
    map.current?.scrollWheelZoom.enable();
  })
  
  map.current?.on('click',()=>{
    if(map.current?.scrollWheelZoom.enabled()){
      map.current?.scrollWheelZoom.disable();
    } else {
      map.current?.scrollWheelZoom.enable();
    }
  })
    
  return (
    <div className={"location-picker"}>
      <MapContainer ref={map} center={getDefaultCenter()} zoom={13} scrollWheelZoom={true}>
        <SearchField />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <LocationMarker position={position} onPositionChange={onMarkerPositionChanged}/>
      </MapContainer>
    </div>
  )
}

const SearchField = () => {
  const provider = new OpenStreetMapProvider();
  //https://github.com/smeijer/leaflet-geosearch/blob/9197b372c28277ff81a28af2afa360fc33d16bc0/src/SearchControl.ts#L125
  const searchControl = new GeoSearchControl({
    provider: provider,
    searchLabel: t('components.location-picker.search'),
    showMarker: false,
  });

  const map = useMap();
  useEffect(() => {
    map.addControl(searchControl);
    return () => {
      map.removeControl(searchControl);
    }
  }, []);

  return null;
}

interface LocationMarkerProps {
  position?: Location|null|undefined,
  onPositionChange?: (position: Location|null) => void
}



const LocationMarker = (props: LocationMarkerProps) => {
  const [position, setPosition] = useState<Location | null | undefined>(props.position);
  useMapEvents({
    click(e: LocationEvent) {
      const newPosition: Location = {
        longitude: e.latlng.lng,
        latitude: e.latlng.lat
      };
      setPosition(newPosition);
      props.onPositionChange && props.onPositionChange(newPosition);
    }
  });

  if(!position)
    return null;
  
  const icon = new Icon ({
    iconUrl : 'location-marker.png',
    iconSize : [23,35], // size of the icon
    iconAnchor : [11,35], // point of the icon which will correspond to marker's location
    popupAnchor : [0, 0] // point from which the popup should open relative to the iconAnchor

  })

  return(
    <Marker icon={icon} position={[position.latitude, position.longitude]}>
      <Popup>You are here</Popup>
    </Marker>
  );
};



export default LocationPicker;