import {NodeModel, useDragOver} from "@minoru/react-dnd-treeview";
import {ArticleMenuItem, ContainerMenuItem, MenuItem, SeparatorMenuItem} from "./Menus";
import {ArrowRight, Pen, PencilFill, Trash} from "react-bootstrap-icons";
import React, {useEffect, useState} from "react";
import ButtonConfirm from "../settings/ButtonConfirm";
import { t } from "i18next";


interface MenuItemComponentProps<T> {
  node: NodeModel<T>;
  depth: number,
  isOpen:boolean,
  onToggle?: (nodeId:number)=>void,
  onDelete?: (nodeId:number)=>void,
  onEdit?: (nodeId:number)=>void
}



export const ContainerMenuItemComponent = (props:MenuItemComponentProps<ContainerMenuItem>)=>{
  const {node}  = props;
  const [imageError, setImageError] = useState<boolean>(false);
  
  useEffect(()=>{
    setImageError(false);
  },[props.node.data.image]);
  
  const dragOverProps = useDragOver(node.id, props.isOpen,props.onToggle);
  const handleToggle = (e:any)=>{
    e.stopPropagation();
    props.onToggle(props.node.id);
  }
  return (
    <div className={"menu-item container"}
         {...dragOverProps}
    >
      
      <span onClick={handleToggle}>
        {imageError && (
          <div className={"image-error"}>No image</div>)
        }
        {!imageError && (
          <img src={`api/pick-n-pay/images/${node.data.image}`} alt={node.data.name} onError={() => setImageError(true)}/>
        )}
        
        {props.node.droppable && (
          <>
            {node.data.name}
          </>
        )}
      </span>

      
      <div className={"tools btn-group"}>
        <button className={"btn btn-edit hover-btn"} onClick={()=>props.onEdit && props.onEdit(node.id)}>
          <span className={"btn-text"}>{t('pick-n-pay.menus.edit-menu.menu-builder.menuItemEdit')}</span>
        </button>

        <ButtonConfirm className={"btn btn-delete hover-btn"} onConfirm={()=>props.onDelete && props.onDelete(node.id)} message={"Are you sure you want to delete this container? all of the items within the container will also be removed."} confirmText={"Delete"}>
          <span className={"btn-text"}>{t('pick-n-pay.menus.edit-menu.menu-builder.menuItemRemove')}</span>
        </ButtonConfirm>
      </div>
    </div>
  )
}

export const ArticleMenuItemComponent = (props: MenuItemComponentProps<ArticleMenuItem>) => {
  const {node} = props;
  const article = node.data?.article;
  
  console.log(article);
  const [imageError, setImageError] = useState<boolean>(false);
  return (
    <div className={`menu-item article ${node.data?.added?"added":""}`}>
      {article && (
        <>
          {imageError && (
            <div className={"image-error"}>No image</div>)
          }
          {!imageError && (
            <img src={`api/pick-n-pay/images/${article.id}`} alt={article.name} onError={() => setImageError(true)}/>
          )}
        </>
      )}
      <span>{node.data.name}</span>
      <span className="article-code">({node.data.code})</span>
      
      
      <div className={"tools btn-group"}>
        <button className={"btn btn-delete hover-btn"} onClick={() => props.onDelete && props.onDelete(node.id)}>
          <span className="btn-text">{t('pick-n-pay.menus.edit-menu.menu-builder.menuItemRemove')}</span>
        </button>
      </div>
      
    </div>
  )
}

export const SeparatorMenuItemComponent = (props: MenuItemComponentProps<SeparatorMenuItem>)=>{
  const {node} = props;
  return (
    <div className={"menu-item separator"}>
      <span>{props.node.data.name}</span>
      <div className={"tools btn-group"}>
        <button className={"btn btn-edit"} onClick={() => props.onEdit && props.onEdit(node.id)}></button>
        <button className={"btn btn-delete"} onClick={() => props.onDelete && props.onDelete(node.id)}></button>
      </div>
    </div>
  )
}