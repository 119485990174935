import {useRef, useState} from "react";
interface Props extends React.TextareaHTMLAttributes<HTMLTextAreaElement>{
  
}

const LineNumberedText = (props:Props)=>{
  const numbersArea = useRef<HTMLDivElement|null>(null);
  const textArea = useRef<HTMLTextAreaElement|null>(null);
  
  if(textArea.current && numbersArea.current){
    const textAreaStyles = window.getComputedStyle(textArea.current);
    [
      'fontFamily',
      'fontSize',
      'fontWeight',
      'letterSpacing',
      'lineHeight',
      'padding',
    ].forEach((property)=>{
      numbersArea.current!.style[property as any] = textAreaStyles[property as any];
    });

    const parseValue = (v:any) => v.endsWith('px') ? parseInt(v.slice(0, -2), 10) : 0;
    const font = `${textAreaStyles.fontSize} ${textAreaStyles.fontFamily}`;
    const paddingLeft = parseValue(textAreaStyles.paddingLeft);
    const paddingRight = parseValue(textAreaStyles.paddingRight);

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context!.font = font;

    const calculateNumLines = (str:string) => {
      if(textArea.current == null){
        return 0;
      }
      if(context == null){
        return 0;
      }
      
      const textareaWidth = textArea.current.getBoundingClientRect().width - paddingLeft - paddingRight;
      const words = str.split(' ');
      let lineCount = 0;
      let currentLine = '';
      for (let i = 0; i < words.length; i++) {
        const wordWidth = context.measureText(words[i] + ' ').width;
        const lineWidth = context.measureText(currentLine).width;

        if (lineWidth + wordWidth > textareaWidth) {
          lineCount++;
          currentLine = words[i] + ' ';
        } else {
          currentLine += words[i] + ' ';
        }
      }

      if (currentLine.trim() !== '') {
        lineCount++;
      }

      return lineCount;
    };

    const calculateLineNumbers = () => {
      if(textArea.current == null)
        return[];
      
      const lines = textArea.current.value.split('\n');
      const numLines = lines.map((line) => calculateNumLines(line));

      let lineNumbers = [];
      let i = 1;
      while (numLines.length > 0) {
        const numLinesOfSentence = numLines.shift();
        // if(!numLinesOfSentence)
        //   throw Error("numLinesOfSentence is null");
        
        lineNumbers.push(i);
        if (numLinesOfSentence > 1) {
          Array(numLinesOfSentence - 1)
            .fill('')
            .forEach((_) => lineNumbers.push(''));
        }
        i++;
      }

      return lineNumbers;
    };

    const displayLineNumbers = () => {
      if(numbersArea.current == null)
        return;
      
      const lineNumberss = calculateLineNumbers();
      numbersArea.current!.innerHTML = Array.from({
        length: lineNumberss.length
      }, (_, i) => `<div>${lineNumberss[i] || '&nbsp;'}</div>`).join('');
    };
    
    displayLineNumbers();
    
    const ro = new ResizeObserver(()=>{
      const rect = textArea.current?.getBoundingClientRect();
      if(rect){
        numbersArea.current!.style.height = `${rect.height}px`;
        displayLineNumbers();
      }
    });
    
    ro.observe(textArea.current);
    textArea.current!.addEventListener("scroll", ()=>{
      numbersArea.current!.scrollTop = textArea.current!.scrollTop;
    });
  }
  
  return (
    <div className={"line-numbered-text"}>
      <div className={"line-numbered-text-line-numbers"} ref={numbersArea} />
      <textarea {...props} className={"line-numbered-text-text"} ref={textArea} />
    </div>
  )
}

export default LineNumberedText;