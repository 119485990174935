import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
// import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./texteditor-plugins/ToolbarPlugin";
import AutoLinkPlugin from "./texteditor-plugins/AutoLinkPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {EditorState} from "@lexical";
import {$generateHtmlFromNodes} from "@lexical/html";

import ExampleTheme from "./texteditor-plugins/exampleTheme";
import SetContentPlugin from "./texteditor-plugins/SetContentPlugin";
import { useRef } from "react";
import {LexicalEditor} from "@lexical";
import {getI18n, I18n, setI18n} from "./PickAndPay/types";

const Placeholder = () =>{
    return (<div className="editor-placeholder">Enter some rich text...</div>);
}

interface EditorProps {
    initialContent?: string;
    locale?: string;
    initialContentI18n?: I18n|null;
    i18nKey?: string;
    
    onChange?: (content:string, contentHtml?:string, updatedI18n?:I18n|null)=>void;
}

const TextEditor = (props:EditorProps)=>{
    const editorConfig = {
        // The editor theme
        theme: ExampleTheme,
        // Handling of errors during update
        onError(error:Error) {
            throw error;
        },
        // Any custom nodes go here
        nodes: [
            HeadingNode,
            ListNode,
            ListItemNode,
            QuoteNode,
            CodeNode,
            CodeHighlightNode,
            TableNode,
            TableCellNode,
            TableRowNode,
            AutoLinkNode,
            LinkNode
        ]
    };
    
    const editorStateRef = useRef();
    
    return (
        <>
        <LexicalComposer initialConfig={editorConfig}>
            <div className={`editor-container lang-${props.locale}`}>
                <ToolbarPlugin />
                <div className="editor-inner">
                    <RichTextPlugin
                        contentEditable={<ContentEditable className="editor-input" />}
                        placeholder={<Placeholder />}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <SetContentPlugin content={props.initialContent} contentI18n={props.initialContentI18n!} i18nKey={props.i18nKey} locale={props.locale} />
                    <OnChangePlugin onChange={(editorState:EditorState, editor:LexicalEditor)=>{
                        editor.update(()=>{
                            const html = $generateHtmlFromNodes(editor, null);
                            if(props.initialContentI18n && props.locale && props.i18nKey){
                                //console.log('updating i18n',props.locale,props.i18nKey, html);
                                setI18n(props.initialContentI18n!, props.i18nKey, props.locale, html);    
                            }
                            
                            props.onChange && props.onChange(
                              JSON.stringify(editorState), 
                              html,
                              props.initialContentI18n
                            );
                        });
                        editorStateRef.current = editorState;
                    }} />
                    
                    <HistoryPlugin />
                    <AutoFocusPlugin />
                    <ListPlugin />
                    <LinkPlugin />
                    <AutoLinkPlugin />
                    
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                </div>
            </div>
        </LexicalComposer>
        </>
    );
    
};

export default TextEditor;