export type PaginatedResult<T> = {
  pageIndex: number;
  pageSize: number;
  totalCount: number;
  totalPages: number;
  items: T[];
}

export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  profitCenters: ProfitCenter[];
  roles: string[];
}

export type ProfitCenter = {
  code: number;
  name: string;
  area: string;
}

export type ProfitCenterArea = {
  code: string;
  description: string;
  creationDate:number;
  modifyDate:number
}

export interface CompareRange{
  compareRangeFrom: string;
  compareRangeTo: string;
}

export type PeriodType = "Day" | "Week" | "Month" | "Quarter" | "QuarterHour" | "Hour" | "Year";

export class RoleNames {
  public static readonly Administrator =            "administrator";
  public static readonly GlobalAccess =             "global-access";
  public static readonly PriceListManager =     "price-list-manager";
  public static readonly PickAndPayManager =  "pick-n-pay-manager";
}

export type ResponseError = {
    title: string;
    details: string;
    status:number;
    errors?: ErrorDetail[]
}

export type ErrorDetail  = {
    key:string,
    value:string
}

export type PriceItem = {
  ean: string;
  description: string;
  category: string;
  type: string;
  description2: string;
  codes: string;
  excluded: boolean;
  defaultPrice: number;
  newPrice: number;
  isExcluded: boolean;
}
