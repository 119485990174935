// @ts-ignore
import {Spinner} from "react-bootstrap";

interface Props {
  visible: boolean;
}
const Loading = (props:Props)=>{
  const {visible} = props;
  return (
    <>
      {visible && (<>
        <Spinner animation="border" variant="primary"/>
        </>
      )}
    </>
  );
};
export default Loading;