import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {$generateNodesFromDOM} from "@lexical/html";
import {useEffect, useRef, useState} from "react";
import {$createParagraphNode, $getRoot} from "lexical";
import {getI18n, I18n} from "../PickAndPay/types";

interface Props {
    content?: string|null|undefined;
    locale?: string;
    contentI18n?: I18n;
    i18nKey?: string;
}

const SetContentPlugin = (props:Props)=>{
    const [editor] = useLexicalComposerContext();
    const doUpdate = useRef(true);
    useEffect(()=>{
        doUpdate.current = true;
    },[props.locale]);
    
    const getDefaultContent = () =>{
        if(props.contentI18n && props.locale && props.i18nKey)
            return getI18n(props.contentI18n, props.i18nKey, props.locale);
        return props.content;
    }
    
    useEffect(()=>{
        editor.update(()=>{
            if(!doUpdate.current){
                //console.log('Not updating editor content');
                return;
            }
            
            const parser = new DOMParser();
            const dom = parser.parseFromString(getDefaultContent() ?? "", "text/html");
            const innerText= dom.body.innerText
            //console.log('Setting editor content to ', innerText);
            const nodes = $generateNodesFromDOM(editor, dom);
            const root = $getRoot();
            root.clear();
            //const paragraph = $createParagraphNode();
            nodes.forEach(n=>root.append(n));
            //root.append(paragraph);
            doUpdate.current = false;
        });
    },[props.locale]);
    return null;
}

export default SetContentPlugin;