import React, {ChangeEvent, useEffect, useState} from "react";
import {cyrb53} from "../../cyrb53";
// @ts-ignore
import {CaretDownFill, CaretRightFill, Check, X} from "react-bootstrap-icons";
import Loading from "../Loading";
import {Campaign} from "./types";
// @ts-ignore
import {useTranslation} from "react-i18next";
import FancyCheckbox from "./FancyCheckbox";
import {CampaignRow} from "./CampaignRow";
// @ts-ignore
import {PaginatedResult} from "../../types";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import {useUser} from "../../UserContext";
import {useNavigate} from "react-router-dom";
import {usePnpLocales} from "./hooks/usePnpLocales";

interface State {
  query: string,
  pageIndex: number,
  enabledFilter: boolean,
  winposActivatedFilter: boolean
}

const Campaigns = () => {
    const {locales} = usePnpLocales();
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [state, setState] = useState<State>({
    query: '',
    pageIndex: 0,
    enabledFilter: false,
    winposActivatedFilter: false
  });

  const {isIntersecting, ref} = useIntersectionObserver({threshold: 0.5});
  
  const navigate = useNavigate();
  const {user} = useUser();
  const {t} = useTranslation();

  useEffect(()=>{
    // if(!user){
    //   navigate('/');
    //   return;
    // }
    
    if(!hasItems){
      return;
    }
    fetchData();
  },[isIntersecting])
  
  useEffect(() => {
    //reset the campaigns when the query changes
    setCampaigns([]);
    setState({...state, pageIndex: 0});
    fetchData();
  }, [state.query, state.winposActivatedFilter, state.enabledFilter]);
  
  const fetchData = ()=>{
    setLoading(true)
    
    const qs = new URLSearchParams();
    qs.set('query', state.query);
    qs.set('pageIndex', state.pageIndex.toString());
    
    if(state.enabledFilter)
      qs.set('fEnabled', 'true');

    if(state.winposActivatedFilter)
      qs.set('fActive', 'true');

    fetch(`api/pick-n-pay/campaigns/search?${qs.toString()}`)
      .then(r => r.json())
      .then(data => {
        const result: PaginatedResult<Campaign> = data;
        setCampaigns((prev)=>[...prev, ...result.items]);
        setState((prev)=>({...prev, pageIndex: result.pageIndex+1}));
        setLoading(false)
      });
  }
  
  const resetForm = () => {
    setState({
      ...state,
      query: '',
      enabledFilter: false,
      winposActivatedFilter: false
    });
  }

  const queryChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      query: e.target.value,
      pageIndex: 0
    });
  }
  const onEnabledFilterChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      enabledFilter: e.target.checked,
      pageIndex: 0
    });
  }

  const onActiveFilterChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      winposActivatedFilter: e.target.checked,
      pageIndex: 0
    });
  }
  
  const getKey = (campaign: Campaign) => {
    return cyrb53(campaign.code);
    //key generator since the campaign id might not be available (not created for web yet)     
    // const key =  campaign.id == null ? cyrb53(campaign.code) : campaign.id;
    // console.log(key, campaign.id, campaign.name);
    // return key;
  }
  const hasItems = campaigns.length > 0;

  const onCampaignUpdated = (c:Campaign) => {
    
  };
  return (
    <div className={"pick-n-pay campaigns"}>
      <div className={"filter-container"}>
        <h1>{t('pick-n-pay.campaigns.header')}</h1>
        <form className={"filters"}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.campaigns.search')}</label>
            <input className={"form-control"} type={"search"} value={state.query} onChange={queryChanged}/>
          </div>
          
          <FancyCheckbox checked={state.winposActivatedFilter} name={"winpos-activated"} label={t('pick-n-pay.campaigns.winpos-activated')} onChange={onActiveFilterChanged}/>
          <FancyCheckbox checked={state.enabledFilter} name={"available-online"} label={t('pick-n-pay.campaigns.visible-online')} onChange={onEnabledFilterChanged}/>
          <button type={"reset"} className={"btn btn-clear"} onClick={resetForm}>{t('pick-n-pay.campaigns.clear')}</button>
        </form>
      </div>
      <div className={"result-container"}>
        <h2>{t('pick-n-pay.campaigns.result-header')}</h2>

        {!hasItems && !loading && (
          <div className={"alert"}><p>{t('pick-n-pay.campaigns.no-campaigns')}</p></div>
        )}
        
        <ol className={"campaign-list"}>
          {campaigns.map(campaign => (
            <li className={"campaign-list-item"} key={getKey(campaign)}>
              <CampaignRow locales={locales} campaign={campaign}/>
            </li>
          ))}
        </ol>
        
        {loading && <Loading/>}
        {hasItems && (
          <div className={"scroll-observer"} ref={ref}></div>
        )}
      </div>
    </div>
  )
}

export default Campaigns;