import React, {useEffect, useState} from "react";
import {ShopDetails} from "./ShopDetails";
import Loading from "../../Loading";
import {Shop} from "../types";
import ProfitCenterPicker from "../../ProfitCenterPicker";
import {useNavigate, useParams} from "react-router-dom";
import {ProfitCenter} from "../../../types";
import {useTranslation} from "react-i18next";

interface State {
    shops: Shop[],
    loading: boolean
}

interface Params {
    shopId?: string
}

const Shops = () => {
    const params = useParams<Params>();
    const [profitCenters, setProfitCenters] = useState([]);
    const [profitCenterId, setProfitCenterId] = useState(params.shopId ?? -1);
    const [loading, setLoading] = useState(false);
    const [shop, setShop] = useState<Shop | null>(null);
    const {t} = useTranslation();
    const navigate = useNavigate();
    
    useEffect(() => {
        setLoading(true);
        fetch('account/explicit-profitcenter-access')
            .then(r => r.json())
            .then(data => {
                setProfitCenters(data);
                if (profitCenterId === -1 && data.length > 0) {
                    setProfitCenterId(data[0].code);
                    profitCenterChanged(data[0]);
                } else {
                    if (params.shopId) {
                        const p = data.find((p: any) => p.code.toString() === params.shopId)
                        if (p) {
                            profitCenterChanged(p);
                        } else if(data.length > 0) {
                            setProfitCenterId(data[0].code);
                        }
                    } else if(data.length > 0) {
                        setProfitCenterId(data[0].code);
                    }
                }
                setLoading(false);
            })
    }, []);

    const updateShop = (id: number) => {
        setLoading(true);
        fetch(`api/pick-n-pay/shops/${id}`)
            .then(r => r.json())
            .then(data => {
                setShop(data);
                setLoading(false);
            });
    }

    const profitCenterChanged = (p: any) => {
        navigate(`/pick-n-pay/shops/${p.code}`);
        setProfitCenterId(p.code);
        updateShop(p.code);
    }

    const getDefaultProfitCenter = () => {
        if (profitCenterId !== -1) {
            return profitCenters.find((p: ProfitCenter) => p.code === profitCenterId);
        }
        return profitCenters[0];
    }
    
    const enableForPickAndPay = () => {
        fetch(`api/pick-n-pay/shops/${profitCenterId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(r => r.json())
            .then(data => {
                setShop(data);
        })
    }
    
    

    if (loading) {
        return (<Loading visible={true}/>);
    }

    return (
        <div className={"pick-n-pay shops"}>
            <div className={"filters filter-container"}>
                <h1>{t ('pick-n-pay.locations.header')}</h1>
                <ProfitCenterPicker profitCenters={profitCenters} selectedProfitCenter={getDefaultProfitCenter()} onProfitCenterSelected={profitCenterChanged}/>
            </div>
            {shop && (
              <>
                  {shop.winposOnly && (
                    <div className={"alert alert-info shop-not-pnp-ready"}>
                        
                        <div className={"alert-content"}>
                            <p>{t ('pick-n-pay.locations.alert.text1')}</p>
                            <p>{t ('pick-n-pay.locations.alert.text2')}</p>
                        </div>
                        
                        
                        <button onClick={enableForPickAndPay} className={"btn btn-primary"}>{t('pick-n-pay.locations.alert.enableButton')}</button>
                    </div>
                  )}
                  {!shop.winposOnly && (
                    <ShopDetails shop={shop}/>
                  )}
              </>
            )}
            {!shop && (
                <div className={"result-container alert"}>
                    <span>{t('pick-n-pay.shops.noShopSelected')}</span>
                </div>
            )}
        </div>
    )
}

export default Shops;