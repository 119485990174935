export const Authenticate = async (email:string, password:string) => {
  return await fetch('/account/login', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ email: email, password: password })
  })
      .then(response => response.json())
      .then(profile => {
        return profile;
      });
}