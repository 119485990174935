import {Shop} from "../types";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import LabelValue from "../LabelValue";

interface Props {
  shop: Shop
}
const OrderShopDetails = (props: Props) => {
  const {shop} = props;
  const {t} = useTranslation();
  return(
    <div className={"order-shop-details"}>
      
      <h2>
        <span>{t('pick-n-pay.orders.details.shop-details.header')}</span>
      </h2>
      <Link to={`/pick-n-pay/shops/${shop.id}`}>{shop.description}</Link>
      <LabelValue label={t('pick-n-pay.orders.details.shop-details.name')} value={shop.name} />  
      <div className={"input-group contact-information"}>
        <span className={"label"}>{t('pick-n-pay.orders.details.shop-details.address')}</span>
        <span className={"address1"}>{shop.contactInformation.address1}</span>
        <span className={"address2"}>{shop.contactInformation.address2}</span>
        <span className={"zip-code"}>{shop.contactInformation.zipCode}</span>
        <span className={"city"}>{shop.contactInformation.city}</span>
      </div>       
      <LabelValue label={t('pick-n-pay.orders.details.shop-details.phone')} value={shop.contactInformation.phone} />
    </div>
  );
}
export default OrderShopDetails;