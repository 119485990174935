import Modal, {ModalPropsBase} from "./Modal";
import {Location} from "./PickAndPay/types";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import LocationPicker from "./LocationPicker";

interface ModalLocationPicker extends ModalPropsBase {
  location: Location,
  showEditButton?: boolean,
  onLocationChange?: (e: Location|null) => void,
  debug?: boolean
}

export const ModalLocationPicker = (props: ModalLocationPicker) => {
  const [open, setOpen] = useState<boolean>(props.isOpen);
  const [location, setLocation] = useState<Location|null>(props.location);
  const {t} = useTranslation();
  useEffect(()=>{
    // Hack to force the map to resize when the modal is opened
    setTimeout(()=>{
      window.dispatchEvent(new Event('resize'));
    });
  },[open])
  const onLocationChange = (e:Location|null)=>{
    setLocation(e);
  }
  
  const clearLocation = ()=>{
    setOpen(false);
    setLocation(null);
    props.onLocationChange && props.onLocationChange(
      {
        latitude: 0,
        longitude:0
      }
    );
  };
  
  const selectLocation = (e:Location)=>{
    setOpen(false)
    props.onLocationChange && props.onLocationChange(location);
  }

  const buttons = [
    { text: t('components.location-picker.clear'), isClose: true, onClick: clearLocation, className: 'btn' },
    { text: t('components.location-picker.cancel'), isClose: true, onClick: ()=>setOpen(false), className: 'btn'},
    { text: t('components.location-picker.select'), isClose: true, onClick: selectLocation, className: 'btn' }
  ];

  return (
    <div className={"input-group"}>
      <span className={"label"}>{t('pick-n-pay.shops.shop-info.latitude')}</span>
      <span className={"longitude"}>{props.location.latitude}</span>

      <span className={"label"}>{t('pick-n-pay.shops.shop-info.longitude')}</span>
      <span className={"longitude"}>{props.location.longitude}</span>

      {props.showEditButton && (
        <button className={"btn btn-edit"} type={"button"} onClick={()=>setOpen(true)}></button>
      )}

      <Modal buttons={buttons} isOpen={open} onClose={()=>setOpen(false)}>
        <h1>{t('components.location-picker.header')}</h1>
        {props.debug && (
          <span>{JSON.stringify(location)}</span>
        )}
        <LocationPicker location={props.location} onLocationChange={onLocationChange}/>
      </Modal>
    </div>
  )
}

ModalLocationPicker.defaultProps = {
  showEditButton: true,
  debug:false
}