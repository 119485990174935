import {useSettings} from "../../SettingsContext";
import {hasI18nLocale, I18n} from "./types";
import React, {useEffect, useState} from "react";

interface Props {
  locale? : string;
  i18n?: I18n|null
  onChange: (locale: string) => void;
  disabled?: boolean;
  locales?: string[];
}

const LocaleSelector = (props:Props)=>{
  const {settings} = useSettings();
  let defaultLocale= props.locale;
  if(!defaultLocale)
    defaultLocale = settings.locale;
  if(!defaultLocale)
    defaultLocale = "en";

  const locales = ()=>{
    if(props.locales)
      return props.locales;
    return settings.supportedLocales;
  }
  
  const [locale, setLocale] = useState<string>(defaultLocale);

  useEffect(() => {
    setLocale(props.locale!);
  }, [props.locale]);
  
  const onLocaleClicked = (e:React.MouseEvent<HTMLButtonElement>, loc:string)=>{
    e.preventDefault();
    setLocale(loc)
    props.onChange && props.onChange(loc);
  }
  
  return (
    <div className={`locale-selector ${props.disabled?"disabled":""}`}>
    {locales().map((loc) => (
        <button disabled={props.disabled} type={"button"} key={loc} onClick={(e)=>onLocaleClicked(e,loc)}
                className={`btn btn-locale lang-${loc} ${hasI18nLocale(props.i18n!, loc) ? 'has-value' : 'empty'} ${loc == locale ? "active" : ""}`}>
          <span className={"sr-only"}>{loc}</span>
        </button>
      ))}
    </div>
  )
}

export default LocaleSelector;