import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ProfitCenter} from "../types";
import Picker, {Option, OptionValue} from "./PickAndPay/settings/Picker";
interface Props {
    showAllOption?: boolean;
    profitCenters: ProfitCenter[];
    selectedProfitCenter?: ProfitCenter;
    onProfitCenterSelected?: (pc: ProfitCenter) => void;
}
const ProfitCenterPicker = (props: Props) => {
  const {showAllOption, profitCenters, selectedProfitCenter, onProfitCenterSelected } = props;
  const [options,setOptions ] = useState<Option[]>([]);
  const [selected, setSelected ]= useState(selectedProfitCenter ?? null);
  const [selectedOption, setSelectedOption] = useState(null);
  const {t} = useTranslation();

  
  
  useEffect(()=>{
    const opts = profitCenters.map(pc => ({label: pc.name, value: pc.code} as Option));
    setOptions(opts);
  },[props.profitCenters]);
  
  if(!profitCenters || profitCenters.length === 0){
    return (<></>);
  }
  
  const selectProfitCenter = (pc:ProfitCenter) =>{
    setSelected(pc);
    if(onProfitCenterSelected)
      onProfitCenterSelected(pc);
  }
  const clearSelection = ()=>{
    selectProfitCenter(null);
  }
  
  const hasMultipleProfitCenters = profitCenters.length > 1;
  const showDropdown = profitCenters.length > 5;
  
  const findByCode = (code:string|number) => {
    return profitCenters.find(pc => pc.code.toString() === code.toString());
  }
  
  const isSelected = (pc:ProfitCenter)=> {
    if(selected != null) {
      if(selected?.code === pc.code)
        return true;
      return false;
    }
    
    return profitCenters[0].code === pc.code;
  }

  
  //const options = profitCenters.map(pc => ({label: pc.name, value: pc.code}));
  
    return (
        <>
          {hasMultipleProfitCenters && !showDropdown && (
          <div className={"row filter profitcenter-picker"}>
            <label>{t('profitcenter-picker.profitcenters')}</label>
            {profitCenters.map((pc) => (
                <button className={'btn btn-picker ' + (isSelected(pc) ? 'selected' : '')} onClick={()=>selectProfitCenter(pc)} key={pc.code}>
                  {pc.name}
                </button>
            ))}
          </div>
          )}
          
          {hasMultipleProfitCenters && showDropdown && (
            <>
              <div className={"row filter profitcenter-picker"}>
                <Picker label={t('profitcenter-picker.profitcenter')}
                        emptyText={t('profitcenter-picker.all')}        
                        options={options} value={selectedProfitCenter?.code} onChange={(o:OptionValue)=>{
                    selectProfitCenter(findByCode(o));
                }} />
                  
                {/*<div className={"input-group"}>*/}
                {/*  <label className={"filter-label"}>{t('profitcenter-picker.profitcenter')}</label>*/}
                {/*  <div className={"select-group"}>*/}
                {/*    <select value={selectedProfitCenter?.code ?? "all"} className={"form-select"} onChange={(e)=>selectProfitCenter(findByCode(e.target.value))}>*/}
                {/*      {showAllOption && (*/}
                {/*          <option value={"all"}>{t('profitcenter-picker.all')}</option>*/}
                {/*      )}*/}
                {/*      */}
                {/*      {profitCenters.map((pc) => (*/}
                {/*          <option key={pc.code} value={pc.code}>{pc.name}</option>*/}
                {/*      ))}*/}
                {/*    </select>*/}
                {/*    */}
                {/*    <button className="btn btn-clear" onClick={()=>clearSelection()} type="submit">{t('common.clear')}</button>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </>
          )}
          
        </>
    );
}

export default ProfitCenterPicker
