import {useTranslation} from "react-i18next";

interface Props {
  customer: any;
  onClick?: (customer:any)=>void;
}
const CustomerRow = (props:Props)=>{
  const {t} = useTranslation();
  const {customer} = props;
  
  return (
    <li className={"customer-list-item"}>
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.customers.list.list-headers.name')}</span>
        <span>{customer.name} {customer.lastName}</span>
      </div>

      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.customers.list.list-headers.id')}</span>
        <span>{customer.customerCode}</span>
      </div>
      
      <div className="result-item">
        <span className="item-name">{t('pick-n-pay.customers.list.list-headers.email')}</span>
        <span>{customer.email}</span>
      </div>
      <div className="result-item">
        <button className={"btn"} onClick={()=>props.onClick && props.onClick(customer)}>
          {t(`pick-n-pay.customers.list.details.text`)}
        </button>
      </div>
    </li>
  );
}

export default CustomerRow