import React from "react";
import {ChevronDown, ChevronUp} from "react-bootstrap-icons";
import {useFormatters} from "./formatters";
import {useSettings} from "../SettingsContext";
interface Props {
  value: number;
  compareValue:number;
  text: string;
  quantity?: number;
  compareQuantity?: number;
  percentage?: number;
  comparePercentage?: number;
  className?: string;
  formatter?: string;
}
const StatisticValue = (props:Props) => {
  const { settings } = useSettings();
  const formatters = useFormatters(settings);

  let currencyFormatter = formatters.currency;
  let numberFormatter = formatters.number;
  let percentFormatter = formatters.percentage;

  const diff = (a:number, b:number):number => {
    return a - b;
  }

  const getFormatter = () => {
    const formatterName = props.formatter ?? "currency";
    if (formatterName === "currency") {
      return currencyFormatter;
    }
    if (formatterName === "number") {
      return numberFormatter;
    }
    if (formatterName === "percent") {
      return percentFormatter;
    }
    
    return numberFormatter;
  }
  
  const getClassName = (calcValue:number) => {
    if(calcValue  === 0){
      return "text-secondary"
    }
    if(calcValue > 0)
      return "text-success";
    return "text-danger";
  };

  const formattedValue = getFormatter().format(props.value);
  
  return (
        <div className={"stats-summary-item " + props?.className ??""}>
          <div className={"content"}>
            <h5 className="header">{props.text}</h5>
            <div className={"value-stat"}>
              {props.quantity != null && (
              <div className={"col"}>
                <span className={"value quantity"}>{formatters.number.format(props.quantity)}</span>
                {props.compareQuantity != null && (
                  <div className={"compare-quantity " + getClassName(diff(props.quantity, props.compareQuantity))}>
                    <small>
                    {diff(props.quantity, props.compareQuantity)> 0 && (<><ChevronUp />+</>) }
                    {diff(props.quantity, props.compareQuantity)< 0 && (<><ChevronDown /></>) }
                    {formatters.number.format(diff(props.quantity, props.compareQuantity))}
                    </small>
                  </div>
                )}
              </div>
              )}

              {props.percentage != null && (
                <div className={"col-percentage"}>
                  <span className={"value percentage"}>{formatters.percentage.format(props.percentage)}</span>
                  {props.comparePercentage != null && (
                    <div className={"compare-percentage " + getClassName(diff(props.percentage, props.comparePercentage))}>
                      <small>
                        {diff(props.percentage, props.comparePercentage)> 0 && (<><ChevronUp />+</>) }
                        {diff(props.percentage, props.comparePercentage)< 0 && (<><ChevronDown /></>) }
                        {formatters.percentage.format(diff(props.percentage,props.comparePercentage))}
                      </small>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className={"row-statistic-value"}>
              <span className={"value statistic-value"}>{formattedValue}</span>
              {props.compareValue != null && !isNaN(props.compareValue) &&  (
                <div className={"compare-value "+ getClassName(diff(props.value, props.compareValue))}>
                  <span>
                  {diff(props.value, props.compareValue)> 0 && (<><ChevronUp />+</>) }
                  {diff(props.value, props.compareValue)< 0 && (<><ChevronDown /></>) }
                  {  getFormatter().format(diff(props.value, props.compareValue))}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
  )
}

export default StatisticValue;