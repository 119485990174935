import FancyCheckbox from "../FancyCheckbox";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DeliverySpot, I18n, OrderType} from "../types";
import Modal, {ModalPropsBase} from "../../Modal";
import Select from "react-select";
import I18nInput from "../../../I18nInput";
import LocaleSelector from "../LocaleSelector";
import {useSettings} from "../../../SettingsContext";
import {enable} from "workbox-navigation-preload";


interface Props {
  spot:DeliverySpot
  onSave?: (spot:DeliverySpot)=>void
  locales: string[]
  open?: boolean
}

const EditDeliverySpotModal = (props:Props) => {
  const {settings} = useSettings();
  const {t} = useTranslation();
  const [loc, setLoc] = useState<string|undefined>(settings.locale);
  const [spot, setSpot] = useState<DeliverySpot>(props.spot);
  const [open, setOpen] = useState<boolean>(props.open ?? false);
  
  const onSave = ()=>{
    console.log('Saving spot',spot);
    props.onSave && props.onSave(spot);
  }
  
  useEffect(()=>{
    setLoc(settings.locale);
  },[settings.loading])
  
  const buttons = [
    { text: t('pick-n-pay.delivery-spots.edit.cancel'), isClose: true, onClick: ()=>setOpen(false), className: 'btn btn-cancel'},
    { text: t('pick-n-pay.delivery-spots.edit.save'), isClose: true, onClick: onSave, className: 'btn btn-primary' }
  ];

  const orderTypeOptions = [
    {value: 'OrderAppTakeAway', label: t(`pick-n-pay.settings.orderTypes.TakeAway`)},
    {value: 'OrderApp', label: t(`pick-n-pay.settings.orderTypes.DineInPlace`)}
  ];
  
  const updateI18n = (i18n:I18n | null)=>{
    if(i18n){
      updateSpot({...spot, i18n: i18n},'updateI18n');
    }
  }
  
  const updateSpot = (spot:DeliverySpot, callee?:string)=>{
    if(callee){
      console.log('updateSpot called by:', callee);
    }else {
      console.log('updateSpot called by unknown');
    }
    setSpot(spot);
  }
  
  return (
    <div className="edit-delivery-spots">
      {props.open === undefined && (
      <button onClick={()=> setOpen(true)} className={"btn btn-edit hover-btn"}>
        <span className="btn-text">{t('pick-n-pay.delivery-spots.result.edit')}</span>
      </button>
      )}

      <Modal buttons={buttons} isOpen={open} onClose={() => setOpen(false)}>
        <h1>{t('pick-n-pay.delivery-spots.edit.header')}</h1>
        <form onSubmit={onSave}>
          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.delivery-spots.edit.name.label')}</label>
            <input className={"form-control"} type={"text"} value={spot.name ?? ""} onChange={(e) => updateSpot({...spot, name: e.target.value}, 'nameTextbox')}/>
            <p className={"form-info"}>{t('pick-n-pay.delivery-spots.edit.name.description')}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t('pick-n-pay.delivery-spots.edit.area.label')}</label>
            <input className={"form-control"} type={"text"} value={spot.area ?? ""} onChange={(e) => updateSpot({...spot, area: e.target.value}, 'areaTextbox')}/>
            <p className={"form-info"}>{t('pick-n-pay.delivery-spots.edit.area.description')}</p>
          </div>

          <div className={"input-group"}>
            <label>{t('pick-n-pay.delivery-spots.edit.enabled.label')}</label>
            <FancyCheckbox checked={spot.enabled} 
                           name={"enabled"}
                           label={spot.enabled ? t('pick-n-pay.delivery-spots.edit.enabled.checked') : t('pick-n-pay.delivery-spots.edit.enabled.unchecked')}
                           onChange={(e) => {
                             updateSpot({...spot, enabled: e.target.checked}, 'enabledCheckbox');
                           }}
            />
            <p className={"form-info"}>{t('pick-n-pay.delivery-spots.edit.enabled.description')}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.delivery-spots.edit.orderType.label`)}</label>
            <Select name={"orderType"}
                    defaultValue={orderTypeOptions.find(o => o.value === spot.orderType)}
                    className={"form-control"}
                    options={orderTypeOptions}
                    onChange={(o: { value: string }) => updateSpot({...spot, orderType: o.value as OrderType}, 'orderTypeSelect')}/>
            <p className={"form-info"}>{t(`pick-n-pay.delivery-spots.edit.orderType.description`)}</p>
          </div>

          <div className={"input-group"}>
            <label className={"form-label input-group-text"}>{t(`pick-n-pay.delivery-spots.edit.i18n.label`)}</label>
            <LocaleSelector onChange={(l) => setLoc(l)} locales={props.locales} locale={loc}/>
            <I18nInput i18n={spot.i18n} i18nKey={"name"} locale={loc ??"en"} placeholder={spot.name} onChange={updateI18n}/>
            <p className={"form-info"}>{t(`pick-n-pay.delivery-spots.edit.i18n.description`)}</p>
          </div>

        </form>
      </Modal>
    </div>
  )
}
export default EditDeliverySpotModal;