import Modal, {ModalButton, ModalPropsBase} from "../../Modal";
import { MenuItem, SeparatorMenuItem } from "./Menus";
import React, {useEffect, useState} from "react";
import LocaleSelector from "../LocaleSelector";
import I18nInput from "../../../I18nInput";
import {I18n} from "../types";
import {useSettings} from "../../../SettingsContext";
import {useTranslation} from "react-i18next";
import {usePnpLocales} from "../hooks/usePnpLocales";

interface SeparatorModalProps extends ModalPropsBase {
  onAdd: (item: SeparatorMenuItem)=>void
  onUpdate: (item: SeparatorMenuItem)=>void
  item?: SeparatorMenuItem|null
  locales: string[]
}

const defaultMenuItem = ()=> new SeparatorMenuItem(MenuItem.emptyId, -1, '', '',{ translations: []});
const clone = (item:MenuItem)=>{
  return JSON.parse(JSON.stringify(item));
}
const SeparatorModal = (props: SeparatorModalProps)=>{
  const {settings}  = useSettings();
  const {isOpen} = props;
  const [menuItem, setMenuItem] = useState<SeparatorMenuItem>(props.item?clone(props.item) : null ?? defaultMenuItem());
  const [locale, setLocale] = useState<string>(settings.locale ?? props.locales[0]);
  const {t} = useTranslation();
  useEffect(()=>{
    if(props.item){
      setMenuItem(clone(props.item));
    }
  },[props.item]);
  
  const clearMenuItem = ()=>{
    setMenuItem(defaultMenuItem());
  }
  
  const onDialogClose = ()=>{
    props.onClose && props.onClose();
    clearMenuItem()
  }

  const onAdd = ()=>{
    props.onAdd && props.onAdd(menuItem);
    clearMenuItem();
  }

  const onUpdate = ()=>{
    props.onUpdate && props.onUpdate(menuItem);
    clearMenuItem();
  }

  const buttons:ModalButton[] = [
    { text: 'Close', isClose: true, onClick: onDialogClose, className: 'btn'}
  ];

  if(props.item){
    buttons.push({ text: 'Update', onClick: onUpdate, className: 'btn btn-save'});
  }else {
    buttons.push({ text: 'Add', onClick: onAdd, className: 'btn btn-save'});
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>)=>{
    const value = e.target.value;
    const name = e.target.name;
    setMenuItem({...menuItem, [name]: value});
  }

  const onLocaleChange = (locale:string)=>{
    setLocale(locale)
  }

  const setI18n = (i18n:I18n | null)=>{
    if(i18n) {
      setMenuItem({
        ...menuItem,
        i18n: i18n!
      });
    }
  }

  return (
    <Modal isOpen={isOpen} buttons={buttons} onClose={onDialogClose}>
      <form>
        <h1>{t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.header')}</h1>
        <div className={"input-group"}>
          <label htmlFor={"name"}>{t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.name.label')}</label>
          <input name={"name"} type={"text"}
                 value={menuItem.name} required={true}
                 placeholder={t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.name.placeholder')}
                 onChange={onInputChange}/>
          <p className={"form-info"}>{t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.name.description')}</p>
        </div>
        
        <div className={"input-group"}>
          <LocaleSelector onChange={onLocaleChange} locale={locale} locales={props.locales}/>
          <label htmlFor={"text"}>{t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.text.label')}</label>
          <I18nInput i18n={menuItem.i18n} i18nKey={"text"} locale={locale} onChange={setI18n}/>
          <p className={"form-info"}>
            {t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.text.description')}
          </p>
        </div>

        <div className={"input-group"}>
          <label htmlFor={"text"}>{t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.description.label')}</label>
          <I18nInput i18n={menuItem.i18n} i18nKey={"description"} locale={locale} onChange={setI18n}/>
          <p className={"form-info"}>
            {t('pick-n-pay.menus.edit-menu.menu-builder.separator-modal.description.description')}
          </p>
        </div>
      </form>
    </Modal>
  )
}
export default SeparatorModal;