import React, {useEffect, useState} from "react";
import {ArticleResult, I18n} from "../types";
import {Link} from "react-router-dom";
import ButtonConfirm from "../settings/ButtonConfirm";
import {useTranslation} from "react-i18next";
import Loading from "../../Loading";

export class MenuItemTypes {
  public static readonly Article =          "article";
  public static readonly Separator =     "separator";
  public static readonly Container =     "container";
}

export abstract class MenuItem {
  "$type":string
  id:string;
  itemId:number;
  parentId:number = 0;
  name: string;
  type: string;
  i18n?: I18n;
  added: boolean = false;
  constructor(id:string, itemId:number, name: string, type: string, i18n?: I18n, added?: boolean){
    this["$type"] = type; //weird AF but the order is important to json deserialization server-side
    this.id = id;
    this.itemId = itemId;
    this.name = name;
    this.type = type;
    this.i18n = i18n ?? { translations: [] };
    this.added = added ?? false;
  }
  
  static emptyId:string = '00000000-0000-0000-0000-000000000000';
}

export class ArticleMenuItem extends MenuItem {
  code: string;
  article: ArticleResult | null;
  constructor(id:string, itemId:number, name: string, code: string, i18n?:I18n, article: ArticleResult | null = null, added?: boolean){
    super(id, itemId, name, MenuItemTypes.Article, i18n, added);
    this.code = code;
    this.article = article;
  }
}

export class SeparatorMenuItem extends MenuItem {
  text:string;
  
  constructor(id:string, itemId:number, name: string,  text:string, i18n?: I18n, added?: boolean){
    super(id, itemId, name, MenuItemTypes.Separator, i18n, added);
    this.text = text;
    this.i18n = i18n ?? { translations: [] };
  }
}

export class ContainerMenuItem extends MenuItem {
  image:string|null;
  constructor(id:string, itemId:number, name: string, i18n?: I18n, image?: string |null, added?: boolean){
    super(id, itemId, name, MenuItemTypes.Container, i18n, added);
    this.image = image ?? null;
  }
}

export class Menu {
  id:number;
  name: string;
  items: MenuItem[];
  shops?: ShopSimple[];
  constructor(id:number, name: string, items: MenuItem[], shops?:ShopSimple[]){
    this.id = id;
    this.name = name;
    this.items = items;
    this.shops = shops;
  }
}
export interface ShopSimple{
  id: number;
  name: string;
}

const Menus = ()=>{
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState<Menu[]>([]);
  const {t, locale} = useTranslation();
  
  useEffect(()=>{
    setLoading(true);
    fetch('api/pick-n-pay/menus')
    .then(response => response.json())
    .then(data => {
      setMenus(data as Menu[]);
      setLoading(false);
    });
  }, []);
  
  const onDelete = (menu:Menu)=>{
    fetch(`api/pick-n-pay/menus/${menu.id}`, {
      method: 'DELETE'
    }).then(()=>{
      setMenus(menus.filter(m=>m.id !== menu.id));
    });
  }
  
  const onDuplicate = (menuId:number)=>{
    fetch(`api/pick-n-pay/menus/${menuId}`)
      .then(r=>r.json())
      .then(data=>{
        const m = data as Menu;
        const clone:Menu = JSON.parse(JSON.stringify(m));
        clone.id = 0;
        clone.name = `${m.name}${t('pick-n-pay.menus.list.copy.suffix')}`;
        clone.items.forEach(i=>{
          i.id = MenuItem.emptyId;
          i.added = true;
        });

        fetch('api/pick-n-pay/menus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(clone)
        }).then(r => r.json())
          .then(data=>{
            const m = data as Menu;
            setMenus([...menus, m]);
          });
        
      });
    
    
    
    //
    
  }
  
  return (
    <div className={"pick-n-pay menu-page"}>
      <h1>{t('pick-n-pay.menus.list.header')}</h1>
      <div className={"filter-container"}>
        <Link to={`/pick-n-pay/menus/new`} className={"btn btn-large-add"}>
          <span className="btn-text">{t('pick-n-pay.menus.list.new.text')}</span>
        </Link>
      </div>

      {loading && <Loading visible={true}/>}
      <div className="search-result-container">
        <ul className={"result-container search-result"}>
          {menus.map((menu, index) => (
          <li className={"article"} key={index}>           
            <h1>{menu.name} ({menu.id})</h1>
            <ol className="profit-centers">
            {menu.shops.slice(0,5).map((shop) => (
              <li key={shop.id} className={"badge"}>
                {shop.name}
              </li>
            ))}
            </ol>
            {menu.shops.length > 5 && <span>{t('pick-n-pay.menus.list.usage', {count: (menu.shops.length - 5)})}</span>}
            <div className={"btn-group"}>
              <Link className={"btn btn-edit hover-btn"} to={`/pick-n-pay/menus/${menu.id}`}>
                <span className="btn-text">{t('pick-n-pay.menus.list.edit.text')} "{menu.name}"</span>
              </Link>
              <ButtonConfirm className={"btn btn-copy hover-btn"} onConfirm={()=>onDuplicate(menu.id)} message={t('pick-n-pay.menus.list.copy.message')} confirmText={t('pick-n-pay.menus.list.copy.confirmText')}>
                <span className="btn-text">{t('pick-n-pay.menus.list.copy.text')}</span>
              </ButtonConfirm>
              <ButtonConfirm className={"btn btn-delete hover-btn"} onConfirm={()=>onDelete(menu)} message={t('pick-n-pay.menus.list.delete.message')} confirmText={t('pick-n-pay.menus.list.delete.confirmText')}>
                <span className="btn-text">{t('pick-n-pay.menus.list.delete.text')}</span>
              </ButtonConfirm>
            </div>
          </li>
          ))}
        </ul>
      </div>
    </div>
  )
};
export default Menus;