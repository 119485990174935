import React, {ChangeEvent, useState} from 'react';
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useNavigate} from "react-router-dom";
import {RoleNames, User} from "../types";
import FancyCheckbox from "./PickAndPay/FancyCheckbox";
import {useTranslation} from "react-i18next";

const CreateUser = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [state, setState] = useState<User>({
        username: "",
        email: "",
        password: "",
        firstName: "",
        lastName: "",
    });
    const [roles, setRoles] = useState<any[]>([
        { role: RoleNames.Administrator, assigned: false},
        { role: RoleNames.GlobalAccess, assigned: false},
        { role: RoleNames.PriceListManager, assigned: false},
        { role: RoleNames.PickAndPayManager, assigned: false}
    ]);

    const submit = (event: Event) => {
        event.preventDefault();
        const body = {
            ...state,
            roles: roles.filter(r => r.assigned).map(r => r.role)
        };
        
        fetch('/api/users', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(response => response.json())
            .then((result) => {
                navigate(`/admin/users/${result.id}`);
            });
    }

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState({
            ...state,
            [name]: value
        });
    }
    const roleChanged = (e:ChangeEvent<HTMLInputElement>) => {
        setRoles(roles.map(r => {
            if(r.role === e.target.name){
                return {role: r.role, assigned: e.target.checked}
            }
            return r;
        }));    
    };
    
    return (
        <div id={"login-container"} className={'admin-page create-user'}>
            <h1>{t('users.createHeader')}</h1>
            <Form className={'form user-info'}>

                <div className={'input-group'}>
                    <label className={"form-label input-group-text"}>{t('users.email')}</label>
                    <input className={"form-control"} type={"email"} name={"email"} value={state.email} onChange={handleChange} required/>
                </div>

                <div className={'input-group'}>
                    <label className={"form-label input-group-text"}>{t('users.firstname')}</label>
                    <input className={"form-control"} name={"firstName"} onChange={handleChange} value={state.firstName} required/>
                </div>

                <div className={'input-group'}>
                    <label className={"form-label input-group-text"}>{t('users.lastname')}</label>
                    <input className={"form-control"} name={"lastName"} value={state.lastName} onChange={handleChange} required/>
                </div>

                
                {roles && roles.map((role)=>(
                  <FancyCheckbox key={role.role} checked={role.assigned} name={role.role} label={t(`users.roles.${role.role}`)} onChange={roleChanged} />
                ))}

                <div className={'btn-group'}>
                    <Button type={"submit"} onClick={submit}>{t('users.createUser')}</Button>
                </div>
            </Form>

            {/*<code>{JSON.stringify(state, null, 4)}</code>*/}
        </div>
    );
}
export default CreateUser;


