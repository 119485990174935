import React, {ChangeEvent, useEffect} from "react";
import {getI18n, I18n, I18nLocale, Sticker, StickerType} from "./types";
//@ts-ignore
import {Plus, X} from "react-bootstrap-icons";
//@ts-ignore
import Loading from "../Loading";
import ImageUpload from "./ImageUpload";
import ButtonConfirm from "./settings/ButtonConfirm";
//@ts-ignore
import {useTranslation} from "react-i18next";
import LocaleSelector from "./LocaleSelector";
import {useSettings} from "../../SettingsContext";
import {findI18nImageId, hasI18nImage, setI18nImageId} from "./functions";
import I18nInput, {I18nSpan} from "../../I18nInput";
import { t } from "i18next";

interface StickerPickerDialogProps {
  onClose?: (stickers: Sticker[]) => void,
  type: StickerType,
  target: string,
  stickers: Sticker[] | null | undefined,
  locales?:string[]
}

const StickerPickerDialog = (props: StickerPickerDialogProps) => {
  const {t} = useTranslation();
  const {settings} = useSettings();
  const {onClose} = props;
  const [stickers, setStickers] = React.useState<Sticker[]>([]);
  const [locale, setLocale] = React.useState<string>(settings.locale ?? "en");
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showCreatePane, setShowCreatePane] = React.useState<boolean>(false);
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [editSticker, setEditSticker] = React.useState<Sticker>({
    id: -1,
    name: '',
    description: '',
    selected: false,
    i18n: {
      translations: []
    }
  });
  const [image, setImage] = React.useState<string | null>();

  useEffect(() => {
    setImage(findI18nImageId(editSticker.i18n!, locale));
  }, [locale, editSticker]);

  useEffect(() => {
    fetch('api/pick-n-pay/stickers')
      .then(r => r.json())
      .then(data => {
        setStickers(data.items.map((s: Sticker) => {
          return {
            ...s,
            selected: props.stickers?.find(st => st.id === s.id) != null
          };
        }));
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    
  }, []);

  const onStickerSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const id: number = Number(e.target.value);
    const selected = e.target.checked;
    const newStickers = stickers.map(s => {
      if (s.id === id) {
        return {...s, selected};
      }
      return s;
    });
    setStickers(newStickers);
  }

  const selectedStickers = () => {
    return stickers.filter(s => s.selected);
  }

  const getEndpoint = (type: StickerType, target: string) => {
    if (type == StickerType.Campaign) {
      return `api/pick-n-pay/campaigns/${target}/stickers`
    }

    if (type == StickerType.Article) {
      return `api/pick-n-pay/articles/${target}/stickers`
    }

    throw new Error('Unknown sticker type');
  }

  const deleteClicked = () => {
    const stickerTODelete = editSticker;
    fetch(`api/pick-n-pay/stickers/${editSticker?.id}`, {
      method: 'DELETE'
    }).then(r => {
      clearEditSticker();
      setShowCreatePane(false);
      setEditMode(false);
      setStickers(stickers.filter(s => s.id !== stickerTODelete?.id));
    })
  }

  const cancelClicked = () => {
    clearEditSticker();
    setShowCreatePane(false);
  }

  const doneClicked = () => {
    const selected = selectedStickers();
    const existing = props.stickers?.map(s => s.id) ?? [];

    selected.forEach(async s => {
      if (existing.find(st => st === s.id) != null) {
        return;
      }

      await fetch(getEndpoint(props.type, props.target), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({stickerId: s.id})
      }).then(r => r.json()).then(data => {

      });
    });

    const toRemove = existing.filter(st => selected.find(s => s.id === st) == null);
    toRemove.forEach(async s => {
      const endpoint = `${getEndpoint(props.type, props.target)}/${s}`
      await fetch(endpoint, {
        method: 'DELETE'
      }).then(r => {

      });
    });

    onClose && onClose(selected);
  }

  const onImageUploaded = (imageId: string | null) => {
    if (editSticker) {
      const i18n = editSticker.i18n
      if (!hasI18nImage(i18n, settings.supportedLocales)) {
        settings.supportedLocales.forEach(loc => {
          setI18nImageId(i18n, loc, imageId)
        });
      } else {
        setI18nImageId(i18n, locale, imageId);
      }

      setEditSticker({
        ...editSticker!,
        i18n: i18n
      });
    }
  };

  const close = () => {
    onClose && onClose(props.stickers ?? []);
  }

  const onInputChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const targetName = e.target.name;
    setEditSticker({
      ...editSticker!,
      [targetName]: e.target.value
    });
  }

  const createSticker = () => {
    //console.log(editSticker);
    fetch('api/pick-n-pay/stickers', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(editSticker)
    }).then(r => r.json()).then(data => {
      setStickers([...stickers, data]);
      clearEditSticker()
      setEditMode(false);
      setShowCreatePane(false);
    })
  }

  const updateSticker = () => {
    
    fetch(`api/pick-n-pay/stickers/${editSticker.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(editSticker)
    }).then(r => r.json()).then(data => {
    })
    clearEditSticker();
    setEditMode(false);
    setShowCreatePane(false);
  }

  const clearEditSticker = () => {
    setEditSticker({
      id: -1,
      name: '',
      description: '',
      selected: false,
      i18n: {
        translations: []
      },
    });
  }

  const editClicked = (s: Sticker) => {
    setShowCreatePane(true);
    setEditMode(true);
    setEditSticker(s);
  }

  const addClicked = () => {
    clearEditSticker();
    setEditMode(false);
    setShowCreatePane(!showCreatePane);
  }
  
  const setI18n = (i18n:I18n | null)=>{
    setEditSticker({
      ...editSticker,
      i18n: editSticker.i18n
    });
    //console.log(i18n);
  }

  return (
    <div className={`sticker-picker-dialog ${editMode ? 'edit-mode' : ''} ${showCreatePane && !editMode ? 'create-mode' : ''}`}>
      <div className={"sticker-picker-dialog-header"}>
        <h1>{t('pick-n-pay.stickers.picker.header')}</h1>
        <button className={"btn btn-close"} onClick={close}>
          <span className={"sr-only"}>{t('pick-n-pay.stickers.picker.close')}</span>
        </button>
      </div>
      <div className={"sticker-picker-dialog-body"}>
        <Loading visible={loading}/>
        <ul>
          {stickers.map(s => {
            return (
              <li key={s.id} className={"sticker"}>
                <label>
                  <input type={"checkbox"} value={s.id} checked={s.selected} onChange={onStickerSelected}/>
                  <div className={"sticker-inner"}>
                    {findI18nImageId(s.i18n, settings.locale!) && (
                      <img src={`/api/pick-n-pay/images/${findI18nImageId(s.i18n,settings.locale!)}`} alt={getI18n(s.i18n,"description", settings.locale!) ??""}/>
                    )}
                    <I18nSpan i18n={s.i18n} i18nKey={"name"} locale={settings.locale!} />
                  </div>

                </label>
                <button className={"btn-edit"} onClick={() => editClicked(s)}></button>
              </li>
            );
          })}
        </ul>
      </div>
      <div className={"sticker-picker-dialog-create-pane"}>
        {!showCreatePane && (
          <button className={"btn btn-large-add"} onClick={addClicked}>{t('pick-n-pay.stickers.picker.addNew')}</button>
        )}
        {showCreatePane && (
          <div className={"sticker-picker-create-pane"}>
            <ImageUpload onImageUploaded={onImageUploaded} currentImage={image}/>
            <form>
              <LocaleSelector locale={locale} locales={props.locales} onChange={setLocale}/>
              <div className={"input-group"}>
                <label className={"form-label input-group-text"}>{t('pick-n-pay.stickers.picker.edit.name')}</label>
                <I18nInput i18n={editSticker.i18n} i18nKey={"name"} locale={locale} onChange={setI18n} />
              </div>
              <div className={"input-group"}>
                <label className={"form-label input-group-text"}>{t('pick-n-pay.stickers.picker.edit.description')}</label>
                <I18nInput i18n={editSticker.i18n} i18nKey={"description"} locale={locale} onChange={setI18n} />
              </div>
              <div className={"form-footer"}>
                {editMode && (
                  <ButtonConfirm title={t('pick-n-pay.stickers.delete.description')} className={"btn btn-remove"} message={t('pick-n-pay.stickers.delete.confirmText')}
                                 onConfirm={deleteClicked}>
                    {t('pick-n-pay.stickers.delete.label')}
                  </ButtonConfirm>
                )}
                <div className={"btn-group from-footer"}>
                  <button type={"button"} className={"btn"} onClick={cancelClicked}>{t('pick-n-pay.stickers.picker.edit.cancelButton')}</button>
                  {editMode && (
                    <button type={"button"} className={"btn btn-primary"} onClick={updateSticker}>{t('pick-n-pay.stickers.picker.edit.saveButton')}</button>
                  )}
                  {!editMode && (
                    <button type={"button"} className={"btn btn-primary"} onClick={createSticker}>{t('pick-n-pay.stickers.picker.edit.addButton')}</button>
                  )}
                </div>


              </div>
            </form>
          </div>
        )}
      </div>
      {!showCreatePane && (
        <div className={"sticker-picker-dialog-footer"}>
          <button className={"btn btn-primary"} area-label={t('pick-n-pay.stickers.picker.doneButton')} onClick={doneClicked}>{t('pick-n-pay.stickers.picker.doneButton')}</button>
        </div>
      )}
    </div>
  );
}

interface StickerPickerProps {
  type: StickerType
  target: string,
  stickers: Sticker[] | null | undefined
  onChange?: (stickers: Sticker[]) => void
  locales?: string[]
}

interface State {
  showPicker: boolean,
}

const StickerPicker = (props: StickerPickerProps) => {
  const [state, setState] = React.useState<State>({
    showPicker: false
  });

  const onShowDialog = () => {
    setState({
      ...state,
      showPicker: !state.showPicker
    });
  }
  const onCancel = () => {
    setState({
      ...state,
      showPicker: false
    });
  }

  const onDialogClosed = (stickers: Sticker[]) => {
    setState({
      ...state,
      showPicker: false
    });

    if (props.onChange) {
      props.onChange(stickers);
    }
  }

  //console.log(props.locales);
  return (
    <div className={"sticker-picker"}>
      <button className={"btn btn-add hover-btn"} onClick={onShowDialog}>
        <span className={"btn-text"}>{t('pick-n-pay.stickers.picker.add')}</span>
      </button>
      {state.showPicker && <StickerPickerDialog onClose={onDialogClosed} type={props.type} target={props.target} stickers={props.stickers} locales={props.locales}/>}
    </div>
  )
}
export default StickerPicker;