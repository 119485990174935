import React, {ChangeEvent, FormEvent, useState} from "react";
import {FormControl, FormLabel} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {Link, useLocation} from "react-router-dom";
import {ErrorDetail, ResponseError} from "../types";
import {useTranslation} from "react-i18next";

const useQuery = ()=>{
  const {search} = useLocation();
  return React.useMemo(()=>new URLSearchParams(search),[search])
}

interface State {
    email:string,
    password: string;
    confirmPassword: string;
}
const SetNewPassword = () => {
  const [state, setState] = useState<State>({ email: "", password:"", confirmPassword:"" });
  //const [showNotMatchingMessage, setShowNotMatchingMessage] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState<ResponseError>(null);
  
  const query = useQuery();
  const {t} = useTranslation();
  const isNewUser = query.get('new') ==='true'
  
    
    
  const submit = (event:FormEvent) => {
    setError(null)
    event.preventDefault();
    
    fetch('account/reset-password',{
      method:"PUT",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          ...state, 
          token: decodeURIComponent(query.get('token') ?? ""), 
          userId: decodeURIComponent(query.get('userId') ?? "")
      })
    })
      .then(response=> {
          if(!response.ok){
              return response.json()
          }
          return response;
      })
      .then(data=>{
          if(data && data.ok) {
            setShowSuccess(true);
            setShowForm(false);
          } else {
            const error = data as ResponseError;
            setShowForm(true);
            setError(error);
          }
    });
  }
  
  const handleChange = (event:ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const value = target.value
    const name = target.name;
    
    setState({
          ...state,
          [name]: value
        }
    );
  }
  
  const  showNotMatchingMessage = state.password !== state.confirmPassword;
  
  return (
      <>
        <div className={"login"}>

          {showForm && (
            <form onSubmit={submit} className="login-dialog">
              <h3>{t("set-password.header")}</h3>
  
              {isNewUser && (
                <div>
                  <p>{t("set-password.new-user.invited-text")}</p>
                  <p>{t("set-password.new-user.when-done-text")}</p>
                </div>
              )}
              
              <div className={"input-group"}>
                <label htmlFor={"password"}>{t("set-password.new-password.label")}</label>
                <FormControl id={"password"} autoComplete={"new-password"} name={"password"} type={"password"} value={state.password} minLength={6} required onChange={handleChange}/>
                
              </div>
              <div className={"input-group"}>
                <FormLabel htmlFor={"email"}>{t("set-password.confirm-password.label")}</FormLabel>
                <FormControl id={"confirmPassword"} autoComplete={"new-password"}  name={"confirmPassword"} type={"password"} minLength={6} value={state.confirmPassword} required onChange={handleChange}/>
                
              </div>
              
              {error &&(
                <div className="alert alert-danger" role="alert">
                  <div>
                    <h3>{t("set-password.errors.header")}</h3>
                    {error.errors && (
                      <ul>
                        {error.errors.map((err:ErrorDetail)=>(
                          <li key={err.key}>
                              {t(`set-password.errors.${err.key}`)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )}
              <div className={"login-footer"}>
                <Button className={"w-100 btn-lg mt-3"} type={"submit"}>
                    {t("set-password.submit")}
                </Button>
              </div>
            </form>
              
              
          )}
          {showSuccess &&(
              <div className="alert alert-success mt-3" role="alert">
                <p>
                    {t("set-password.success.text1")}
                    <Link to={'/login'} className={"alert-link"}>{t("set-password.success.text2")}</Link>
                    {t("set-password.success.text3")}
                </p>
              </div>
          )}
        </div>
      </>
  );
}

export default SetNewPassword;
