import React, { useState } from 'react';
import { t } from "i18next";
import {Check} from "react-bootstrap-icons";
import {ProfitCenter} from "../types";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
interface Props {
    profitCenters: ProfitCenter[];
    selectedProfitCenters: ProfitCenter[];
    onClose: () => void;
}
export function SelectProfitCenter(props:Props) {
    const {profitCenters} = props;
    const {onClose} = props;
    const [show, setShow] = useState(false);
    const [filter, setFilter] = useState("");
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSave = ()=>{
        setShow(false);
        onClose();
    };
    
    const selectedChanged = (profitcenter:ProfitCenter, selected:boolean) =>{
        if(selected){
            if(!props.selectedProfitCenters.find(x=>x.code === profitcenter.code)){
                props.selectedProfitCenters.push(profitcenter);
            }
        } else {
            const item = props.selectedProfitCenters.find(x => x.code === profitcenter.code);
            if(item)
                props.selectedProfitCenters.splice(props.selectedProfitCenters.indexOf(item),1);
        }
    }
    
    const isSelected = (profitCenter:ProfitCenter)=>{
        return props.selectedProfitCenters.find(pc=>pc.code === profitCenter.code) !== undefined;
    };
    
    const shouldRender = (profitCenter:ProfitCenter) =>{
        return profitCenter.name.toLowerCase().indexOf(filter.toLowerCase()) > -1;
    }
    
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                {t('users.profitcenterSelector.profitcenterSelectButton')} 
            </Button>
            <Modal show={show} centered={true} onHide={handleClose} dialogClassName={"profitceter-dialog"}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('users.profitcenterSelector.header')} </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className={"list-group list"}>
                        {profitCenters.map((pc)=>
                             <ProfitCenterLine shouldRender={shouldRender(pc)} key={pc.code} selected={isSelected(pc)} profitCenter={pc} onSelectedChanged={selectedChanged} />
                        )}
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="" onClick={handleClose}>{t('users.profitcenterSelector.cancel')}</Button>
                    <Button variant="primary" onClick={handleSave}>{t('users.profitcenterSelector.save')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

interface ProfitCenterLineProps {
    profitCenter: ProfitCenter;
    selected: boolean;
    onSelectedChanged: (profitCenter:ProfitCenter, selected:boolean) => void;
    shouldRender: boolean;
}
const ProfitCenterLine = (props: ProfitCenterLineProps)=>{
    const {profitCenter, onSelectedChanged} = props;
    const [selected, setSelected] = useState(props.selected)
    
    if(!props.shouldRender)
        return <></>
    
    const toggleSelected = () =>{
        const newValue = !selected;
        setSelected(newValue);
        if(onSelectedChanged)
            onSelectedChanged(profitCenter,newValue);
    };
    
    let classNames = "list-item";
    if(selected)
        classNames = `list-item selected`;
    
    return (<li className={classNames} onClick={() => toggleSelected()}> 
        <span>{profitCenter.name}</span>
    </li>)
}