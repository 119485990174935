import {useEffect, useState} from "react";
import {PnpSettings} from "../settings/Settings";

export const UsePnPSettings = () => {
  const [settings, setSettings] = useState<PnpSettings|null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);
    fetch('api/pick-n-pay/settings')
      .then(response => response.json())
      .then((data)=>{
        setSettings(data);
        setLoading(false);
      });
  }, []);;
  return { settings, loading };
};