import React, {useEffect} from "react";
import {Authenticate} from "./Authenticate";
import {useNavigate} from "react-router-dom";
import {User} from "./types";
import {useInterval} from "usehooks-ts";

export const UserContext = React.createContext<User|null>(
  null//{ email:"", displayName:"",isAdministrator:false}
);

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

export const UserProvider = ({children}:Props) => {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<User|null>(null);
  const [loading, setLoading] = React.useState(true);
  const handleLogin = async (email:string, password:string):Promise<boolean> => {
    let success = true;
    let profile = await Authenticate(email,password)
      .catch((err)=> {
        success = false;
      });
    setUser(profile);
    return success;
  }

  const handleLogout = ()=>{
    fetch('/account/logout').finally(()=>{
      setUser(null);
      navigate('/');
    });
  }

  const value = {
    user,
    loading,
    onLogin: handleLogin,
    onLogout: handleLogout,
  }

  useEffect(()=>{
    setLoading(true);
    fetch('/account/profile')
      .then(response => response.json())
      .then(profile => {
        setLoading(false);
        setUser(profile);
      })
      .catch(err => {
        setLoading(false);
      });
  },[]);

  useInterval(()=>{
    if(user) {
      fetch('/account/profile')
        .then(response => {
          if(response.status === 401) {
            throw new Error("Unauthorized");
          }
          return response.json();
        })
        .catch(err => {
          handleLogout();
        });
    }
  },300000); //every 5 minutes


  return (
    <UserContext.Provider value={value}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = ()=>{
  return React.useContext(UserContext);
}